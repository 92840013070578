import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { SignatureConfiguration } from '@augusthealth/models/com/august/protos/signable_form'
import { Task } from '@augusthealth/models/com/august/protos/task'
import classNames from 'classnames'
import { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'
import { UrlAndContentType } from '@shared/api/request'
import { SignatureInfoWithSkip } from '@shared/api/task'
import {
  ActionButtonHolder,
  DownloadButton,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import { LightboxSidebarMessage } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { useHasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { contactsPathForPerson, getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import { printFileFromIFrame } from '@shared/utils/iframePrint'
import { AsyncResult } from '@shared/utils/loading'
import {
  extractTemplateSigners,
  getTemplateSigner,
} from '@shared/utils/signableForm'
import {
  isAdminSigner,
  isExternalSigner,
  isResidentSigner,
  isRpSigner,
} from '@shared/utils/signer'
import { replaceSignerPreservingRoleIndex } from '@app/components/SignatureFlow/helpers'
import {
  SkippedSignerRow,
  SkipSignerInput,
} from '@app/components/SignatureFlow/SkipSigners'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import styles from './styles.module.css'
import PDFReader from '../generic/PDFReader'
import ChangeSigners from './ChangeSigners'
import WithSignerDescription from './WithSignerDescription'

export function ReviewSignatures({
  currentSigners,
  originalConfiguredSigners,
  setCurrentSigners,
  responsiblePerson,
  person,
  task,
  disableChangeSigner,
}: {
  currentSigners: SignatureInfoWithSkip[]
  originalConfiguredSigners: SignatureInfoWithSkip[]
  setCurrentSigners: React.Dispatch<
    React.SetStateAction<SignatureInfoWithSkip[]>
  >
  responsiblePerson: Contact | undefined
  person: Person
  task: Task
  disableChangeSigner: boolean
}) {
  const canChangeSigners = useHasPermissionForPerson({
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_FACILITY_USER_READ],
  })
  const { signableForm } = useContext(TaskTemplateContext)
  const templateSigners = extractTemplateSigners({ template: signableForm })

  return (
    <div className="mb-[40px]">
      {currentSigners.map((currentSig, ix) => {
        const originalSigner =
          originalConfiguredSigners[ix].signer || currentSig.signer
        const templateSigner: SignatureConfiguration | undefined =
          getTemplateSigner(templateSigners, originalSigner!)

        if (currentSig?.signer?.skip) {
          return (
            <SkippedSignerRow
              setCurrentSigners={setCurrentSigners}
              index={ix}
              key={ix}
              disabled={disableChangeSigner}
            />
          )
        }

        if (
          originalSigner &&
          (isAdminSigner(originalSigner) || isExternalSigner(originalSigner)) &&
          canChangeSigners
        ) {
          return (
            <ChangeSigners
              templateSigner={templateSigner}
              disableChangeSigner={disableChangeSigner}
              signerIndex={ix}
              key={ix}
              currentSigner={currentSig}
              person={person as Required<Person>}
              task={task}
              setSigner={(newSigner) =>
                setCurrentSigners((prev) =>
                  replaceSignerPreservingRoleIndex({
                    previous: prev,
                    newSigner,
                    ix,
                  })
                )
              }
              currentSigners={originalConfiguredSigners}
              skipSignerComponent={
                templateSigner?.canSkip
                  ? () => (
                      <SkipSignerInput
                        index={ix}
                        setCurrentSigners={setCurrentSigners}
                        templateSigner={templateSigner}
                        inline
                        disabled={disableChangeSigner}
                      />
                    )
                  : undefined
              }
            />
          )
        } else if (originalSigner && isAdminSigner(originalSigner)) {
          return (
            <SignerRow
              key={ix}
              title={getFullName(currentSig?.signer?.name)}
              subTitle={
                <WithSignerDescription templateSigner={templateSigner}>
                  <SkipSignerInput
                    templateSigner={templateSigner}
                    index={ix}
                    setCurrentSigners={setCurrentSigners}
                    disabled={disableChangeSigner}
                  />
                </WithSignerDescription>
              }
              state={'Waiting for signature'}
            />
          )
        } else if (originalSigner && isRpSigner(originalSigner)) {
          if (responsiblePerson?.name) {
            return (
              <SignerRow
                key={ix}
                title={getFullName(responsiblePerson.name)}
                subTitle={
                  <WithSignerDescription templateSigner={templateSigner}>
                    <SkipSignerInput
                      templateSigner={templateSigner}
                      index={ix}
                      setCurrentSigners={setCurrentSigners}
                      disabled={disableChangeSigner}
                    />
                  </WithSignerDescription>
                }
                state={'Waiting for signature'}
              />
            )
          } else {
            return (
              <SignerRow
                key={ix}
                title="Missing Responsible Person"
                subTitle={
                  <WithSignerDescription templateSigner={templateSigner}>
                    <Link
                      className={styles.addRpLink}
                      to={contactsPathForPerson(person as Required<Person>)}
                    >
                      Add a responsible person
                    </Link>
                    <SkipSignerInput
                      templateSigner={templateSigner}
                      index={ix}
                      setCurrentSigners={setCurrentSigners}
                      disabled={disableChangeSigner}
                    />
                  </WithSignerDescription>
                }
                state={'Missing Information'}
              />
            )
          }
        } else if (
          originalSigner &&
          isResidentSigner(originalSigner) &&
          person.name?.[0]
        ) {
          return (
            <SignerRow
              key={ix}
              title={getFullName(person.name[0])}
              subTitle={
                <WithSignerDescription templateSigner={templateSigner}>
                  <SkipSignerInput
                    templateSigner={templateSigner}
                    index={ix}
                    setCurrentSigners={setCurrentSigners}
                    disabled={disableChangeSigner}
                  />
                </WithSignerDescription>
              }
              state={'Waiting for signature'}
            />
          )
        }

        return null
      })}
    </div>
  )
}

export function PDFBody({
  blobData,
  setIsLoaded,
  subHeaderComponent,
}: {
  blobData: AsyncResult<UrlAndContentType, any>
  setIsLoaded?: (loaded: boolean) => void
  subHeaderComponent?: React.ReactNode
}) {
  return (
    <>
      {blobData.tag === 'Loading' && <SimpleSpinner />}
      {blobData.tag === 'Complete' && (
        <PDFReader
          pdfPath={blobData.value.url}
          scale={1.5}
          documentProps={{ error: <></> }}
          setIsLoaded={setIsLoaded}
          subHeaderComponent={subHeaderComponent}
        />
      )}
    </>
  )
}

export function CompleteFormButton({
  person,
  task,
  onClose,
  history,
}: {
  person: Person
  task: Task
  onClose: () => void
  history: any
}) {
  return (
    <AsyncIconButton
      isLoading={false}
      buttonStyle={'secondary-outline'}
      id={'completeFormBtn'}
      className={'mb-[16px]'}
      onClick={() => {
        onClose()
        history.push(
          getTaskUrl({
            facilityId: person.facilityId || '',
            personId: person.id || '',
            orgId: person.orgId || '',
            id: task.id,
            page: 'requiredFields',
          })
        )
      }}
      title="Some required fields are missing, please fill them before submit"
    >
      Fill Required Fields
    </AsyncIconButton>
  )
}

export function PrintDownloadButtons({
  blobData,
}: {
  blobData: AsyncResult<UrlAndContentType, any>
}) {
  let url: string | undefined
  let filename: string | undefined
  const data = blobData.tag === 'Complete' && blobData.value
  if (data) {
    url = data.url
    filename = data.filename
  }

  return (
    <ActionButtonHolder showTopHR>
      <PrintButton
        loading={!url}
        onClick={() => url && printFileFromIFrame(url)}
      />
      <DownloadButton download={filename} href={url} loading={!url} />
    </ActionButtonHolder>
  )
}

export function StaticReviewCopy({ signable = true }: { signable?: boolean }) {
  const reviewCopy = signable
    ? 'Please review the document before signing.'
    : 'Please review the document.'
  const looksGoodCopy = signable
    ? 'If everything looks good, continue to sign.'
    : 'If everything looks good, complete the document.'

  return (
    <LightboxSidebarMessage>
      <div>{reviewCopy}</div>
      <div>{looksGoodCopy}</div>
    </LightboxSidebarMessage>
  )
}

export function SignerRow({
  title,
  subTitle,
  state,
  additionalContent,
  id,
}: {
  title: ReactNode
  subTitle?: ReactNode
  state:
    | 'Signed'
    | 'Waiting for signature'
    | 'Completed'
    | 'Missing Information'
  additionalContent?: ReactNode
  id?: string
}) {
  const _iconClass = classNames(styles.signatureFlowIcon, 'fa-fw fa-light', {
    ['fa-file-signature text-august-primary']: state === 'Signed',
    ['fa-file-signature text-secondary-08']: state === 'Waiting for signature',
    ['fa-file-circle-check text-august-primary']: state === 'Completed',
    ['fa-question text-secondary-08']: state === 'Missing Information',
  })

  return (
    <div className={styles.signatureFlowHolder}>
      <i className={_iconClass} />
      <div>
        <div id={id} data-testid={id} className={styles.signatureFlowTitle}>
          {title}
        </div>
        {subTitle ? (
          <div className={styles.signatureFlowSubTitle}>{subTitle}</div>
        ) : undefined}
        {additionalContent}
      </div>
    </div>
  )
}
