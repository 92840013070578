import { SignableForm } from '@augusthealth/models/com/august/protos/signable_form'
import { DataType } from '@augusthealth/models/com/august/protos/snapshot'
import { Task } from '@augusthealth/models/com/august/protos/task'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { createSignaturePacket, SignatureInfoWithSkip } from '@shared/api/task'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import {
  isResponsiblePerson as contactIsResponsiblePerson,
  primaryEmail,
} from '@shared/utils/contact'
import { AsyncResult, getOrElse, mapAsyncResult } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import {
  isFirstSignerRp as _isFirstSignerRp,
  requiresRpSignature as _requiresRpSignature,
  firstSignerRole,
} from '@shared/utils/signableForm'
import {
  rpCanCreateSignaturePacket,
  signatureInfoForUser,
} from '@shared/utils/task'
import { isResponsiblePerson } from '@shared/utils/user'
import { PageGeneratorItem } from '@app/components/Prospects/Forms/FormLayout/type'
import {
  buildCurrentSignersFromTemplate,
  getNextRequiredSigner,
  pdfUrl,
  useIsTaskIncomplete,
} from '@app/components/SignatureFlow/helpers'
import useBlobData from '@app/hooks/useBlobData'
import { useContacts } from '@app/hooks/useContacts'
import usePerson from '@app/hooks/usePerson'
import { useTasks } from '@app/hooks/useTasks'

export default function useSignatureFlowData({
  person: initialPerson,
  signableForm,
  task,
  dataType,
  pageGeneratorConfiguration: configuration,
}: {
  person: Person
  signableForm: AsyncResult<SignableForm, 'Not Found'>
  task: Task
  dataType?: DataType
  pageGeneratorConfiguration?: PageGeneratorItem[]
}) {
  const { user } = useUserContext()
  const { refreshTasks } = useTasks(true)

  const [currentSigners, setCurrentSigners] = useState<SignatureInfoWithSkip[]>(
    []
  )

  // A special case when assign RP to External, SignerRole will change for currentSigners
  // So having originalConfiguredSigners to track initial configured roles and descriptions
  const [originalConfiguredSigners, setOriginalConfiguredSigners] = useState<
    SignatureInfoWithSkip[]
  >([])

  useEffect(() => {
    if (signableForm.tag === 'Complete') {
      const signers = buildCurrentSignersFromTemplate({
        templateSigners: signableForm.value.template?.signers,
        user,
        person: initialPerson,
      })
      setOriginalConfiguredSigners(signers)
      setCurrentSigners(signers)
    }
  }, [signableForm.tag])

  // We need to re-fetch because the task may have modified the data on the person
  // and some person-data may be a 'required field' for the form
  const { person } = usePerson({ initialData: initialPerson })

  // Ask if the Task is complete (i.e. required fields are filled out)
  const isTaskIncomplete = useIsTaskIncomplete({
    configuration,
    dataType,
    person,
    task,
  })
  const blobData = useBlobData(pdfUrl({ person: initialPerson, task }))
  const { contacts } = useContacts()

  const firstSigner = getOrElse(
    mapAsyncResult(signableForm, firstSignerRole),
    undefined
  )
  const responsiblePerson = getOrElse(contacts, []).find((c) =>
    contactIsResponsiblePerson(c)
  )
  const requiresRpSignature = getOrElse(
    mapAsyncResult(signableForm, _requiresRpSignature),
    false
  )
  const rpEmail = responsiblePerson && primaryEmail(responsiblePerson)
  const isFirstSignerRp = getOrElse(
    mapAsyncResult(signableForm, _isFirstSignerRp),
    false
  )

  const infoForUser = signatureInfoForUser({
    user,
    currentAdminSigners: currentSigners,
  })
  const nextRequiredSigner = getNextRequiredSigner({ signers: currentSigners })
  const { orgId, facilityId, id: personId } = initialPerson
  const isRP = isResponsiblePerson({ user, orgId, facilityId, personId })
  const isCurrentUserNextSigner =
    (isRP && isFirstSignerRp) || isEqual(infoForUser, nextRequiredSigner)

  const determineAction: () => RequestSignatureAction = () => {
    if (isRP && rpCanCreateSignaturePacket({ task })) {
      return {
        tag: 'Create Signature Packet',
        isCurrentUserNextSigner,
      }
    } else if (isRP) {
      return { tag: 'Request Review' }
    } else if (
      rpEmail === undefined &&
      requiresRpSignature &&
      responsiblePerson
    ) {
      return { tag: 'No RP Email' }
    } else if (responsiblePerson === undefined && requiresRpSignature) {
      return { tag: 'No RP' }
    } else {
      return {
        tag: 'Create Signature Packet',
        isCurrentUserNextSigner,
      }
    }
  }

  const createPacket = async () => {
    if (person.tag === 'Complete') {
      let signers = currentSigners.map((s) => s.signer).filter(notEmpty)
      if (isRP) {
        // When the RP creates a signature packet,
        // the backend figures out who the other signers are
        signers = []
      }

      await createSignaturePacket({
        person: person.value,
        task,
        signers,
      })
    }
  }

  return {
    responsiblePerson,
    person,
    user,
    isTaskIncomplete,
    blobData,
    contacts,
    refreshTasks,
    requiresRpSignature,
    determineAction,
    currentSigners,
    setCurrentSigners,
    originalConfiguredSigners,
    createPacket,
    isCurrentUserNextSigner,
    isLoadingData:
      isTaskIncomplete.tag === 'Loading' ||
      person.tag === 'Loading' ||
      contacts.tag === 'Loading' ||
      firstSigner === undefined,
  }
}

export type RequestSignatureAction =
  | { tag: 'No RP' }
  | { tag: 'No RP Email' }
  | { tag: 'Create Signature Packet'; isCurrentUserNextSigner: boolean }
  | { tag: 'Request Review' }
