import { useContext, useEffect, useState } from 'react'
import { getBillingStatementPreview } from '@shared/api/billing'
import { apiUrl, personUrl } from '@shared/api/urls'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DetailedStatement } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'
import useBlobData from '../useBlobData'
import { useCurrentFacility } from '../useFacilities'
import usePerson from '../usePerson'

type Props = {
  invoiceId: string
  orgId: string
  facilityId: string
  personId: string
}

export default function useBillingInvoicePreview(props: Props) {
  const { orgId, facilityId, invoiceId, personId } = props
  const [detailedInvoice, setDetailedInvoice] =
    useState<Loading<DetailedStatement>>(LOADING)
  const { setError } = useContext(GlobalContext)
  const { currentFacility } = useCurrentFacility()
  const { person } = usePerson({
    initialData: {
      orgId,
      facilityId,
      id: personId,
    },
  })
  const fileUrl = apiUrl(
    personUrl(orgId, facilityId, personId),
    `/billing/statements/:invoiceId.pdf`,
    { invoiceId }
  )
  const invoiceBlob = useBlobData(fileUrl)

  async function reloadInvoicePreviewData() {
    try {
      const dataIsLoaded =
        currentFacility?.tag === 'Complete' &&
        currentFacility &&
        person.tag === 'Complete'

      if (!dataIsLoaded) return

      const invoice = await getBillingStatementPreview({
        orgId,
        facilityId,
        invoiceId,
        personId,
      })

      setDetailedInvoice(loaded(invoice))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    if (person.tag === 'Complete' && currentFacility?.tag === 'Complete') {
      // Wait for currentFacility and Person being fetched before loading Invoice
      void reloadInvoicePreviewData()
    }
  }, [person.tag, currentFacility?.tag])

  return {
    detailedInvoice,
    invoiceBlob,
    reloadInvoicePreviewData,
    person,
  }
}
