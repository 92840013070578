import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import {
  MedicationOrder,
  MedOrderFormData,
  OrderAndDiff,
} from '@shared/types/medication_order'
import { UserAccount } from '@shared/types/user'
import { getMedicationOrderInstructions } from '@shared/utils/medicationOrder'
import {
  isCommunityManaged,
  isDiscontinued,
  isPending,
  isPendingDiscontinue,
  isRejected,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import { isSuperUser } from '@shared/utils/user'
import { MedicationBreadcrumbs } from '@app/components/Breadcrumbs/MedicationBreadcrumbs'
import MedicationSearchAndInstructions from '@app/components/Residents/Medications/MedicationSearch/MedicationSearchAndInstructions'
import { MedicationSpecialtyBadges } from '@app/components/Residents/Medications/MedicationSpecialtyBadges/MedicationSpecialtyBadges'
import {
  DetailSectionWrapper,
  FullMedicationOrderWrapper,
  OrderSectionWrapper,
} from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/layout'
import OrderHistory from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/OrderHistory'
import ReviewOrderHoldForCard from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderHoldForCard/ReviewOrderHoldForCard'
import ReviewOrderTreatmentCard from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderTreatmentCard'
import { ReviewPendingChangesCard } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewPendingChangesCard'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { usePersonInContext } from '@app/hooks/usePerson'
import styles from '../styles.module.css'
import { OrderFooter } from '../OrderFooter'
import { OrderHeader } from '../OrderHeader'
import {
  doesFormHaveMissingMedicationInformation,
  doesFormHaveSchedulingErrors,
  mapFormMedToMedOrder,
} from './helpers'
import MedicationAdministrationHistory from './MedicationAdministrationHistory'
import ReviewOrderPrescriptionDetailsCard from './ReviewOrderPrescriptionDetailsCard'
import ReviewOrderScheduleCard from './ReviewOrderScheduleCard/ReviewOrderScheduleCard'

type Props = {
  medicationOrder: MedicationOrder
  pageTitle: string
  mode: 'edit' | 'review'
  orderDiffs?: OrderAndDiff
  canEdit: boolean
  user: UserAccount
}

export default function ReviewMedicationOrderContents({
  medicationOrder,
  pageTitle,
  mode,
  orderDiffs,
  canEdit,
  user,
}: Props) {
  const page = useCurrentPage()
  const { orgId, facilityId, personId } = extractIds(page)
  const { watch, formState } = useFormContext<MedOrderFormData>()
  const [showOrderEdit, setShowOrderEdit] = useState(false)
  const { person } = usePersonInContext({
    initialData: {
      orgId,
      facilityId,
      id: personId,
    },
    skipFirstFetch: true,
  })

  const {
    currentFacility,
    settings: { use24HourClock },
  } = useCurrentFacility()

  if (!person) {
    return null
  }

  const timeZone =
    currentFacility?.tag === 'Complete' && currentFacility.value
      ? currentFacility.value.timeZone
      : undefined

  const mergedWithForm = mapFormMedToMedOrder({
    formData: watch(),
    medOrder: medicationOrder,
  }) as MedicationOrder

  const statement = medicationOrder.medicationStatement!
  const instructions = getMedicationOrderInstructions(medicationOrder)

  const isPendingDiscontinueMed = isPendingDiscontinue(medicationOrder)

  const isDiscontinuedMed = isPending(medicationOrder)
    ? isPendingDiscontinueMed
    : isDiscontinued(medicationOrder.medicationStatement)

  const readOnly = getReadOnlyStatus({
    mode,
    canEdit,
    isDiscontinuedMed,
    isRejectedMed: isRejected(medicationOrder),
  })

  const isOrderEditable =
    isCommunityManaged(medicationOrder) && mode === 'edit' && !readOnly

  const showRequiredFieldsAtTop =
    doesFormHaveMissingMedicationInformation(formState)

  return (
    <>
      <MedicationBreadcrumbs
        person={person}
        medicationOrder={medicationOrder}
      />
      <FullMedicationOrderWrapper title={pageTitle}>
        <OrderSectionWrapper showRequired={showRequiredFieldsAtTop}>
          <Card className={tw`relative`}>
            {showOrderEdit ? (
              <MedicationSearchAndInstructions
                order={medicationOrder}
                mode={'edit'}
              />
            ) : (
              <>
                {isOrderEditable && (
                  <button
                    data-testid={'drug-edit'}
                    onClick={() => setShowOrderEdit(true)}
                    className={tw`absolute right-[16px] top-[16px]`}
                  >
                    <Icon name={'pencil'} />
                  </button>
                )}
                <div className={styles.orderCard}>
                  <OrderHeader order={mergedWithForm} renderMode={'order'} />
                  <div className={`${styles.medInstructions} text-gray-07`}>
                    {instructions.map((text, index) => (
                      <span
                        key={`${
                          statement!.medication!.drugName
                        }-${index}-dosage-instructions`}
                        className={'block'}
                      >
                        {text}
                      </span>
                    ))}
                  </div>
                  <OrderFooter order={mergedWithForm} renderMode={'order'} />
                  <MedicationSpecialtyBadges
                    className={tw`mt-4 border-t border-gray-11 pt-4`}
                    readOnly={readOnly}
                  />
                </div>
              </>
            )}
          </Card>
        </OrderSectionWrapper>

        {orderDiffs && <ReviewPendingChangesCard orderDiffs={orderDiffs} />}

        <DetailSectionWrapper
          showRequired={doesFormHaveSchedulingErrors(formState)}
        >
          <ReviewOrderScheduleCard
            order={medicationOrder}
            use24HourTime={use24HourClock}
            readOnly={readOnly}
          />
          {!isPendingDiscontinueMed && (
            <>
              <ReviewOrderTreatmentCard readOnly={readOnly} />
              <ReviewOrderPrescriptionDetailsCard
                order={medicationOrder}
                readOnly={readOnly}
                person={person}
              />
              <ReviewOrderHoldForCard
                use24HourTime={use24HourClock}
                readOnly={readOnly}
                order={medicationOrder}
              />
            </>
          )}
        </DetailSectionWrapper>
        <div>
          {
            // Pending new orders and the first order in a group have no history, so we don't show the history card
            medicationOrder.orderGroupId && medicationOrder.replacesId && (
              <OrderHistory
                person={person}
                orderGroupId={medicationOrder.orderGroupId}
                facilityTimeZone={timeZone}
                use24HourClock={use24HourClock}
              />
            )
          }
        </div>

        {isSuperUser(user) && medicationOrder.orderGroupId && (
          <MedicationAdministrationHistory
            person={person}
            orderGroupId={medicationOrder.orderGroupId}
            facilityTimeZone={timeZone}
            use24HourClock={use24HourClock}
          />
        )}
      </FullMedicationOrderWrapper>
    </>
  )
}

const getReadOnlyStatus = ({
  mode,
  canEdit,
  isDiscontinuedMed,
  isRejectedMed,
}: {
  mode: 'edit' | 'review'
  canEdit: boolean
  isDiscontinuedMed: boolean
  isRejectedMed: boolean
}): boolean => {
  if (!canEdit) {
    return true
  }

  if (mode === 'edit') {
    return isDiscontinuedMed
  } else if (mode === 'review') {
    return isDiscontinuedMed || isRejectedMed
  }

  return false
}
