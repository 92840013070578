import { sortBy } from 'lodash'
import { Controller, UseFormReturn } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import {
  BillingFee,
  BillingFeesWithParentCategory,
  BillingFrequency,
  RecurringCharge,
} from '@shared/types/billing'
import { getOrElse, loaded, Loading } from '@shared/utils/loading'
import { BillingChargeFormData } from '../helpers'

export default function FeeDropdown({
  billingItemsWithParentCategories,
  useFormReturn,
  setFrequency,
  isCredit = false,
  disabled,
  oneTimeChargeOnly = false,
  disableOptionsBasedOnCharge,
}: {
  billingItemsWithParentCategories: Loading<BillingFeesWithParentCategory[]>
  useFormReturn: UseFormReturn<BillingChargeFormData>
  setFrequency?: (f: BillingFrequency) => void
  isCredit?: boolean
  disabled?: boolean
  oneTimeChargeOnly?: boolean
  disableOptionsBasedOnCharge?: RecurringCharge
}) {
  const options = getOptions(getOrElse(billingItemsWithParentCategories, []), {
    oneTimeChargeOnly,
    disableOptionsBasedOnCharge,
  })

  const { control, formState, setValue } = useFormReturn
  const { errors } = formState

  return (
    <div>
      <LabelAboveInput
        uppercase={false}
        subLabel={disabled ? undefined : requiredLabel(Boolean(errors.itemId))}
        htmlFor="itemId"
      >
        Fee
      </LabelAboveInput>
      {!loaded(billingItemsWithParentCategories) && <Skeleton height={40} />}
      <Controller
        control={control}
        name="itemId"
        rules={{ required: true }}
        render={({ field: { onChange, value: itemId } }) => {
          const selectedGroup = options.find((g) =>
            g.options.some((o) => o.value.meta.id === itemId)
          )
          const selectedOption = selectedGroup
            ? selectedGroup.options.find((o) => o.value.meta.id === itemId)
            : undefined

          return (
            <StyledSelect
              inputId="itemId"
              placeholder="Enter or select a category or a fee..."
              required
              onChange={({
                value: billingItem,
              }: OptionTypeBase<BillingFee>) => {
                setValue('name', billingItem.data.name)

                const sign = isCredit ? -1 : 1
                const amount = billingItem.data.amountCents
                setValue('amountCents', amount * sign)
                setFrequency?.(billingItem.data.frequency)
                onChange(billingItem.meta.id)
              }}
              options={options}
              name="itemId"
              value={selectedOption}
              isDisabled={disabled}
            />
          )
        }}
      />
    </div>
  )
}

function categoryForCharge({
  billingItemsWithParentCategories,
  charge,
}: {
  billingItemsWithParentCategories: BillingFeesWithParentCategory[]
  charge: RecurringCharge
}) {
  return billingItemsWithParentCategories.find((itemWithCategory) =>
    itemWithCategory.items.some((item) => item.meta.id === charge.data.itemId)
  )?.category
}

/**
    If oneTimeChargeOnly is true, only one-time charges will be enabled.
    If disableOptionsBasedOnCharge is provided, only charges of the same category and frequency will be enabled.

    TODO: This is exported only for testing purposes - we should either move it to a helpers file,
    or test the `FeeDropdown` itself and not this function.
*/
export function getOptions(
  billingItemsWithParentCategories: BillingFeesWithParentCategory[],
  options?: {
    oneTimeChargeOnly: boolean
    disableOptionsBasedOnCharge?: RecurringCharge
  }
) {
  const { oneTimeChargeOnly, disableOptionsBasedOnCharge } = options || {}

  const relevantCategory = disableOptionsBasedOnCharge
    ? categoryForCharge({
        billingItemsWithParentCategories,
        charge: disableOptionsBasedOnCharge,
      })
    : undefined
  const relevantFrequency = disableOptionsBasedOnCharge
    ? disableOptionsBasedOnCharge.item.data.frequency
    : undefined

  return sortBy(
    billingItemsWithParentCategories.map((b) => {
      return {
        label: b.category.data.name,
        value: b.category.meta.id,
        options: sortBy(
          b.items.map((item) => {
            const {
              data: { name, frequency },
            } = item

            let isDisabled =
              oneTimeChargeOnly && frequency !== BillingFrequency.ONE_TIME
            if (relevantCategory && relevantFrequency) {
              isDisabled =
                item.data.categoryId !== relevantCategory.meta.id ||
                item.data.frequency !== relevantFrequency
            }

            return {
              label: name,
              subLabel: getSubLabel(frequency),
              value: item,
              isDisabled,
            }
          }),
          ['label']
        ),
      }
    }),
    ['label']
  )
}

function getSubLabel(frequency: BillingFrequency) {
  if (frequency === BillingFrequency.ONE_TIME) {
    return 'One-time'
  } else if (frequency === BillingFrequency.DAILY) {
    return 'Daily recurring'
  } else if (frequency === BillingFrequency.MONTHLY) {
    return 'Monthly recurring'
  }

  return ''
}
