import { endOfMonth, format, formatISO, parseISO } from 'date-fns'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createStatements } from '@shared/api/billing'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DraftStatementsRequest } from '@shared/types/billing'
import { getBillingPeriodOptions } from '@shared/utils/billing'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import BillingModal from '../components/BillingModal'

type Props = {
  closeFn: () => void
  doneFn: () => Promise<void>
  facilityId: string
  orgId: string
  residentIds: string[]
  hideResidentDropdown?: boolean
}

export default function InvoiceModal(props: Props) {
  const {
    orgId,
    facilityId,
    residentIds,
    doneFn,
    closeFn,
    hideResidentDropdown,
  } = props
  const { setError } = useContext(GlobalContext)
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors, isSubmitting, isLoading },
  } = useForm<DraftStatementsRequest>({
    defaultValues: {
      people: residentIds,
    },
  })

  const { residents } = useCurrentFacility()
  const currentYear = new Date().getFullYear()

  async function onSubmit(formData: DraftStatementsRequest) {
    try {
      await createStatements({
        orgId,
        facilityId,
        data: {
          people: formData.people,
          startDate: format(parseISO(formData.startDate!), 'yyyy-MM-dd'),
          endDate: format(parseISO(formData.endDate!), 'yyyy-MM-dd'),
          notes: formData.notes,
        },
      })
      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  const residentOptions = getOrElse(residents, []).map((r) => {
    return {
      value: r.person.id,
      label: getFirstAndLastName(r.person.name),
    }
  })

  return (
    <BillingModal
      title="Add Statement"
      id="add-invoice-modal"
      onClose={closeFn}
    >
      <form
        className={tw`mb-[32px]`}
        data-testid="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!hideResidentDropdown && (
          <div>
            <LabelAboveInput
              uppercase={false}
              subLabel={requiredLabel(Boolean(errors.people))}
              htmlFor="people"
            >
              Resident(s)
            </LabelAboveInput>
            <Controller
              control={control}
              name="people"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                const matchingOpt = residentOptions.filter((opt) =>
                  value?.includes(opt.value)
                )

                return (
                  <StyledMultiSelect
                    defaultValue={matchingOpt}
                    placeholder="Enter resident name or select..."
                    onChange={(opts: OptionTypeBase<string>[]) => {
                      if (!opts || opts.length === 0) {
                        onChange([])
                      } else {
                        onChange(opts.map((o) => o.value))
                      }
                    }}
                    value={value ? matchingOpt : undefined}
                    options={residentOptions}
                    inputId="people"
                    name="people"
                  />
                )
              }}
            />
          </div>
        )}
        <div className={tw`mt-[24px]`}>
          <LabelAboveInput
            uppercase={false}
            subLabel={requiredLabel(
              Boolean(errors.startDate || errors.endDate)
            )}
            htmlFor="billingPeriod"
          >
            Billing Period
          </LabelAboveInput>
          <StyledSelect
            required
            inputId="billingPeriod"
            options={getBillingPeriodOptions()}
            onChange={(e: OptionTypeBase) => {
              const startDate = new Date(currentYear, parseInt(e.value), 1)
              const endDate = endOfMonth(startDate)
              setValue('startDate', formatISO(startDate))
              setValue('endDate', formatISO(endDate))
            }}
          />
        </div>
        <div className={tw`mt-[24px]`}>
          <LabelAboveInput uppercase={false} htmlFor="notes">
            Notes
          </LabelAboveInput>
          <BasicTextarea {...register('notes')} />
        </div>

        <div className="mt-8 flex gap-3">
          <AsyncIconButton
            buttonStyle="secondary-outline"
            type="button"
            onClick={closeFn}
            disabled={isSubmitting}
            isLoading={isLoading}
          >
            Cancel
          </AsyncIconButton>
          <AsyncIconButton
            isLoading={isLoading}
            disabled={isSubmitting}
            buttonStyle="primary-fill"
            type="submit"
          >
            Save
          </AsyncIconButton>
        </div>
      </form>
    </BillingModal>
  )
}
