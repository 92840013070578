import { useState } from 'react'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { BillingFee } from '@shared/types/billing'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { twx } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'
import useBillingItems from '@app/hooks/useBillingItems'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import FeeModal from '@app/pages/Facilities/Billing/Settings/Modals/FeeModal'
import { FeesColumnName, getSearchedFees, getSortedFees } from './helpers'

type Props = {
  facility: Facility
  organization: Organization
  onUpdate: (msg: string) => void
}

export default function FeesTable({ facility, organization, onUpdate }: Props) {
  const { id: orgId } = organization
  const { billingItems, reloadBillingItems } = useBillingItems({
    orgId,
    facilityId: facility.id,
  })
  const [feeInModal, setFeeInModal] = useState<BillingFee | null>(null)

  if (!facility) {
    return <SimpleSpinner />
  }

  const headers = [
    { className: 'w-[16%]', label: FeesColumnName.BILLING_CATEGORY },
    { className: 'w-[16%]', label: FeesColumnName.GL_CODE },
    { className: 'w-[16%]', label: FeesColumnName.DESCRIPTION },
    { className: 'w-[16%]', label: FeesColumnName.FREQUENCY },
    { className: 'w-[16%]', label: FeesColumnName.AMOUNT },
    { className: 'w-[16%]', label: FeesColumnName.APPLIES_TO },
  ]

  return (
    <>
      <WithTable
        title="Fees"
        rows={billingItems}
        headers={headers}
        defaultSelectedColumn={FeesColumnName.BILLING_CATEGORY}
        sortingFn={getSortedFees}
        addBtnProps={{
          label: 'Add Fee',
          onClick: () => setFeeInModal({} as BillingFee),
        }}
        searchBoxProps={{
          searchFn: getSearchedFees,
          searchPlaceholder: 'Type in Description ...',
        }}
      >
        {(sortedRows: BillingFee[]) => (
          <>
            {sortedRows?.map((fee, ix) => {
              const {
                data: { name, facilityId: fId, amountCents, frequency, glCode },
                meta: { id },
                category: {
                  data: { name: categoryName },
                },
              } = fee
              const price = amountCents
                ? formatCurrencyForBilling(amountCents)
                : '-'

              return (
                <TrWithHoverEffect
                  data-testid={`fee-${ix}`}
                  id={id}
                  key={id}
                  onClick={() => {
                    setFeeInModal(fee)
                  }}
                >
                  <TdWithExtraPadding title={categoryName}>
                    {categoryName}
                  </TdWithExtraPadding>
                  <TdWithExtraPadding>{glCode}</TdWithExtraPadding>
                  <TdWithExtraPadding>{name}</TdWithExtraPadding>
                  <TdWithExtraPadding>
                    {convertEnumValueToLabel(frequency)}
                  </TdWithExtraPadding>
                  <TdWithExtraPadding
                    className={twx('font-semibold', {
                      'text-gray-03': amountCents >= 0,
                      'text-tags-orange': amountCents < 0,
                    })}
                  >
                    {price}
                  </TdWithExtraPadding>
                  {!fId && (
                    <TdWithExtraPadding>
                      <i className="fas fa-house-building mr-[8px] text-gray-07" />
                      All Communities
                    </TdWithExtraPadding>
                  )}
                  {fId && (
                    <TdWithExtraPadding>{facility.name}</TdWithExtraPadding>
                  )}
                </TrWithHoverEffect>
              )
            })}
          </>
        )}
      </WithTable>
      {feeInModal && (
        <FeeModal
          fee={feeInModal}
          closeFn={() => setFeeInModal(null)}
          doneFn={(msg: string) => {
            onUpdate(msg)
            return reloadBillingItems()
          }}
          facility={facility}
          organization={organization}
        />
      )}
    </>
  )
}
