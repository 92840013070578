import {
  DosageV2,
  DoseAndRate,
  SlidingScaleEntry,
} from '@augusthealth/models/com/august/protos/dosage'
import {
  DosageType,
  MedicationStatement,
} from '@augusthealth/models/com/august/protos/medication_statement'
import { ReactNode } from 'react'
import { FormState } from 'react-hook-form'
import Pill from '@shared/components/Pill'
import {
  ValidParameterConditional,
  ValidParameterType,
} from '@shared/types/dosage'
import { MedOrderFormData } from '@shared/types/medication_order'
import {
  getReadableParameterConditional,
  getReadableParameterType,
  parameterTypeToUnitLabel,
} from '@shared/utils/dosageParameters'
import {
  getDoseQuantityUnit,
  getDoseQuantityValue,
  getSlidingScaleEntriesFromDose,
  getStructuredSlidingScaleDisplayData,
} from '@shared/utils/medicationOrder'
import { getDosageUnitDisplay } from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'
import pluralize from '@shared/utils/pluralize'
import { tw, twx } from '@shared/utils/tailwind'
import { getFormattedPeriodAndFrequencyDisplayData } from '@shared/utils/timing'
import {
  VITALS_INPUT_PROPS_MAP,
  VitalTypeEnumToVitalsType,
} from '@shared/utils/vitals'
import { hasTouchedSlidingScaleEntries } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { isTaperDosageType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'

const outsideBadgeTextClasses =
  'text-secondary-07 text-[14px] font-medium leading-[16px]'
export const PeriodAndFrequencyBadge = ({ dose }: { dose?: DosageV2 }) => {
  if (!dose) {
    return null
  }

  const badge = getFormattedPeriodAndFrequencyDisplayData(dose.timing)

  return <ReviewOrderBadge>{badge}</ReviewOrderBadge>
}
export const ReviewOrderBadge = (props: {
  children: ReactNode
  className?: string
  ignoreBaseClass?: boolean
  badgeStyle?: 'default' | 'alert'
}) => {
  if (!props.children) {
    return null
  }

  const classes = twx(
    'inline-flex bg-primary-light text-white',
    {
      capitalize: !props.ignoreBaseClass,
      'normal-case': props.ignoreBaseClass,
      'border border-form-alert bg-form-alert text-white':
        props.badgeStyle === 'alert',
    },
    props.className
  )

  return (
    <Pill shape="rectangle" size={'medium'} className={classes}>
      {props.children}
    </Pill>
  )
}

export const QuantityAndDosageBadge = ({
  dose,
  className,
}: {
  dose: DosageV2
  className?: string
}) => {
  const value = getDoseQuantityValue(dose)
  const unit = getDoseQuantityUnit(dose)

  const hasSlidingScale = getSlidingScaleEntriesFromDose(dose).length > 0

  if (!value || hasSlidingScale) {
    return null
  }

  return (
    <ReviewOrderBadge ignoreBaseClass={true} className={className}>
      {pluralize(getDosageUnitDisplay(unit), value, true)}
    </ReviewOrderBadge>
  )
}

export const SlidingScaleBadges = ({
  scaleEntries,
  formState,
}: {
  scaleEntries?: SlidingScaleEntry[]
  formState: FormState<MedOrderFormData>
}) => {
  if (!scaleEntries || scaleEntries.length === 0) {
    return null
  }
  const structuredData = getStructuredSlidingScaleDisplayData(scaleEntries)

  return (
    <div className={'h-fit w-full'}>
      {structuredData.map(
        ({ rangeText, administerDose, isValidRange, note }, index) => {
          if (!hasTouchedSlidingScaleEntries(formState) && !isValidRange) {
            return null
          }
          return (
            <div
              key={`sliding-scale-range-${index}`}
              className={twx('my-2 flex items-center gap-2', {
                'mt-4': index === 0,
              })}
            >
              <span className={outsideBadgeTextClasses}>If</span>
              <ReviewOrderBadge
                ignoreBaseClass={true}
                badgeStyle={isValidRange ? 'default' : 'alert'}
              >
                {rangeText}
              </ReviewOrderBadge>
              <span className={outsideBadgeTextClasses}>administer</span>
              <ReviewOrderBadge>
                {administerDose.toLowerCase()}
              </ReviewOrderBadge>
              {note && <ReviewOrderBadge>{note}</ReviewOrderBadge>}
            </div>
          )
        }
      )}
    </div>
  )
}

export const ConditionalParameterBadges = ({ dose }: { dose: DosageV2 }) => {
  if (!dose.parameters || dose.parameters.length === 0) {
    return null
  }

  return (
    <div className={tw`h-fit w-full`}>
      {dose.parameters.map((param, index) => {
        const paramType = param.parameterType
          ? getReadableParameterType(param.parameterType as ValidParameterType)
          : null
        const conditional = param.conditional
          ? getReadableParameterConditional(
              param.conditional as ValidParameterConditional
            )
          : null

        const numericValue =
          isNaN(param.numeric?.value as number) || !param.numeric?.value
            ? null
            : [
                param.numeric?.value,
                param.parameterType &&
                  parameterTypeToUnitLabel(
                    param.parameterType as ValidParameterType
                  ),
              ].join(' ')

        return (
          <div
            key={`param-${index}`}
            className={twx('my-2 flex items-center gap-2', {
              'mt-4': index === 0,
            })}
          >
            <span className={outsideBadgeTextClasses}>Hold If</span>
            <ReviewOrderBadge
              ignoreBaseClass={true}
              badgeStyle={paramType ? 'default' : 'alert'}
            >
              {paramType ?? 'Not specified'}
            </ReviewOrderBadge>
            <span className={outsideBadgeTextClasses}>is</span>
            <ReviewOrderBadge
              ignoreBaseClass={true}
              badgeStyle={conditional && numericValue ? 'default' : 'alert'}
            >
              {`${conditional ?? 'N/A'} ${numericValue ?? '?'}`}
            </ReviewOrderBadge>
          </div>
        )
      })}
    </div>
  )
}

export const PRNBadges = ({ dose }: { dose?: DoseAndRate }) => {
  if (!dose) {
    return null
  }

  return (
    <>
      <ReviewOrderBadge>PRN</ReviewOrderBadge>
      {!!dose?.doseQuantity?.value && (
        <>
          <ReviewOrderBadge ignoreBaseClass>
            {dose.minTimeBetweenDose?.value && <>Up to </>}
            {pluralize(
              dose.doseQuantity?.unit?.toLowerCase() ?? 'unit',
              dose.doseQuantity.value,
              true
            )}
            {dose.minTimeBetweenDose?.value && (
              <>
                {' '}
                every {pluralize('hour', dose.minTimeBetweenDose.value, true)}
              </>
            )}
          </ReviewOrderBadge>
        </>
      )}
    </>
  )
}

export const VitalBadge = ({
  medStatement,
}: {
  medStatement?: MedicationStatement
}) => {
  if (
    !medStatement?.requiredVitals ||
    medStatement.requiredVitals.length === 0
  ) {
    return null
  }

  const matchingVitals = medStatement.requiredVitals
    .map((vi) => {
      const matchingVitalType = vi && VitalTypeEnumToVitalsType(vi)

      return matchingVitalType
        ? VITALS_INPUT_PROPS_MAP(matchingVitalType)
        : null
    })
    .filter(notEmpty)

  if (matchingVitals.length === 0) {
    return null
  }

  return (
    <>
      <span className={twx(outsideBadgeTextClasses)}>Check</span>
      {matchingVitals.map((vi, vIndex) => {
        return (
          <ReviewOrderBadge key={`${vi.name}-${vIndex}`}>
            {vi.label}
          </ReviewOrderBadge>
        )
      })}
    </>
  )
}

export const DurationBadge = ({
  dose,
  dosageType,
  className,
}: {
  dose: DosageV2
  dosageType: DosageType
  className?: string
}) => {
  if (!isTaperDosageType(dosageType)) {
    return
  }
  const readableDuration =
    dose.timing?.durationUnit?.replace(/^UNIT_OF_TIME_/, '').toLowerCase() ?? ''

  if (!dose.timing?.oneTimeOnly && dose.timing?.duration) {
    return (
      <ReviewOrderBadge className={className}>
        {dose.timing?.durationUnit &&
          pluralize(readableDuration, dose.timing.duration, true)}
      </ReviewOrderBadge>
    )
  }

  const { periodUnit } = dose.timing!
  const readablePeriodUnit =
    periodUnit?.replace(/^UNIT_OF_TIME_/, '').toLowerCase() ?? ''

  if (!dose.timing?.duration) {
    return (
      <ReviewOrderBadge ignoreBaseClass={true} className={className}>
        Every {readablePeriodUnit} thereafter
      </ReviewOrderBadge>
    )
  }

  return null
}
