import { sortBy } from 'lodash'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { upsertBillingFee } from '@shared/api/billing'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
  SingleValue,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  BillingCategory,
  BillingFee,
  BillingFeeData,
  BillingFrequency,
} from '@shared/types/billing'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { DeepNull } from '@shared/types/utilities'
import { isEmptyObject } from '@shared/utils/common'
import { getOrElse } from '@shared/utils/loading'
import { hasFullOrgAccess } from '@shared/utils/user'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import useBillingCategories from '@app/hooks/useBillingCategories'
import { FREQUENCY_OPTIONS } from '@app/pages/Facilities/Billing//Settings/helpers'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'
import CurrentOrgFacilitySelect from '../shared/CurrentOrgFacilitySelect'

type Props = {
  fee: BillingFee | null
  facility: Facility
  organization: Organization
  doneFn: (msg: string) => Promise<void>
  closeFn: () => void
}

export default function FeeModal(props: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { fee, facility, organization, doneFn, closeFn } = props
  const { orgId, id: facilityId } = facility
  const hasFullAccess = hasFullOrgAccess({ orgId, user })
  const facilityIdIsRequired = !hasFullAccess
  const isAddMode = isEmptyObject(fee)
  const { billingCategories } = useBillingCategories({
    orgId,
    facilityId,
  })
  const isOrgLevelFee = fee?.data && !fee.data.facilityId
  const readOnly = isOrgLevelFee && !hasFullAccess
  const disabled = billingCategories.tag === 'Loading' || readOnly

  const { handleSubmit, register, control, formState, setValue, watch } =
    useForm<DeepNull<BillingFeeData>>({
      defaultValues: fee?.data || { orgId, facilityId },
    })
  const { errors } = formState
  const availableCategories = getOrElse(billingCategories, [])
  const selectedCategoryId = watch('categoryId')
  const selectedCategory =
    (selectedCategoryId &&
      availableCategories.find((c) => c.meta.id === selectedCategoryId)) ||
    null
  const isFacilityLevelCategory = Boolean(selectedCategory?.data.facilityId)
  const includeOrgOption = !isAddMode || hasFullAccess
  const categoryOptions = sortBy(
    availableCategories.map((cat) => {
      const { name, facilityId: fId } = cat.data

      return {
        label: name,
        subLabel: fId ? undefined : 'All Communities',
        value: cat,
      }
    }),
    ['label']
  )

  async function onSubmit(billingItemData: BillingFeeData) {
    try {
      await upsertBillingFee({
        orgId,
        billingItemData,
        itemId: fee?.meta?.id ?? '',
      })
      await doneFn(isAddMode ? 'Fee added!' : 'Fee updated!')
      closeFn()
    } catch (err) {
      setError(err)
    }
  }
  const title = readOnly ? 'View Fee' : isAddMode ? 'Add Fee' : 'Edit Fee'
  const disabledFacilityDropdown =
    !isAddMode ||
    disabled ||
    !hasFullAccess ||
    isFacilityLevelCategory ||
    !selectedCategory

  return (
    <BillingModal id="billing-fee-modal" onClose={closeFn} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <LabelAboveInput
            uppercase={false}
            htmlFor="categoryId"
            subLabel={requiredLabel(Boolean(errors.categoryId))}
          >
            Category
          </LabelAboveInput>
          <Controller
            control={control}
            rules={{ required: true }}
            name="categoryId"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  inputId="categoryId"
                  onChange={(opt: OptionTypeBase<BillingCategory>) => {
                    const newCategory = opt.value
                    const {
                      data: { facilityId: fId },
                      meta,
                    } = newCategory

                    onChange(meta.id)
                    setValue(
                      'facilityId',
                      facilityIdIsRequired ? facilityId : fId || null
                    )
                  }}
                  options={categoryOptions}
                  value={
                    categoryOptions.find((o) => o.value.meta.id === value) ||
                    null
                  }
                  components={{ SingleValue }}
                  isDisabled={!isAddMode || disabled}
                />
              )
            }}
          />
        </div>
        <div className="mt-4">
          <LabelAboveInput uppercase={false} htmlFor="facilityId">
            Community
          </LabelAboveInput>
          <Controller
            control={control}
            rules={{ required: false }}
            name="facilityId"
            render={({ field: { onChange, value } }) => {
              return (
                <CurrentOrgFacilitySelect
                  onChange={onChange}
                  facility={facility}
                  selectedFacilityId={value as string | undefined}
                  organization={organization}
                  isDisabled={disabledFacilityDropdown}
                  includeOrgOption={includeOrgOption}
                />
              )
            }}
          />
        </div>
        <div className="mt-4">
          <LabelAboveInput
            uppercase={false}
            htmlFor="glCode"
            subLabel={requiredLabel(Boolean(errors.glCode))}
          >
            GL Code
          </LabelAboveInput>
          <BasicInput
            {...register('glCode', { required: true })}
            disabled={disabled}
          />
        </div>
        <div className="mt-4">
          <LabelAboveInput
            uppercase={false}
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Description as it appears on the invoice
          </LabelAboveInput>
          <BasicInput
            {...register('name', { required: true })}
            disabled={disabled}
          />
        </div>
        <div className="mt-4">
          <LabelAboveInput
            uppercase={false}
            htmlFor="frequency"
            subLabel={requiredLabel(Boolean(errors.frequency))}
          >
            Frequency
          </LabelAboveInput>
          <Controller
            control={control}
            rules={{ required: true }}
            name="frequency"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  onChange={(e: OptionTypeBase<BillingFrequency>) =>
                    onChange(e.value)
                  }
                  options={FREQUENCY_OPTIONS}
                  value={FREQUENCY_OPTIONS.find((o) => o.value === value)}
                  inputId="frequency"
                  isDisabled={disabled}
                />
              )
            }}
          />
        </div>
        <div className="mt-4 flex">
          <div>
            <MoneyInReactHookForm
              uppercaseTitle={false}
              control={control}
              inputProps={{
                disabled,
              }}
              name="amountCents"
              id="amountCents"
              title="Amount"
            />
          </div>
        </div>
        <div className="mt-8">
          <AnimatedPopupFormFooter
            yesBtn={{
              hideButton: readOnly,
              label: isAddMode ? 'Add' : 'Edit',
              props: { disabled },
            }}
            noBtn={{
              action: closeFn,
              label: readOnly ? 'Close' : 'Cancel',
            }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
