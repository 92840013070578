import { MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import {
  billingInvoicePreviewRoute,
  billingRouteForPerson,
} from '@shared/routes'
import { ResidentListEntry } from '@shared/types/billing'
import { FacilityIds } from '@shared/types/facility'
import { ResidentStatus } from '@shared/types/person'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import { formatRoomNumber } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import {
  NameTd,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import ProfileImage from '@app/components/Table/ProfileImage'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import {
  BillingInfoPill,
  BillingInvoicePill,
} from '@app/pages/Facilities/Billing/Components/Pills'

export default function BillingResidentRow({
  facilityIds: { orgId, id: facilityId },
  resident,
}: {
  facilityIds: FacilityIds
  resident: ResidentListEntry
}) {
  const history = useHistory()
  const {
    gender,
    name,
    personId,
    profilePictureUrl,
    pendingBillingEvents,
    totalBalanceCents,
    statementBalanceCents,
    lastInvoice,
    residentStatus,
    roomDetails,
  } = resident
  const isMovedOut =
    residentStatus === ResidentStatus.RESIDENT_STATUS_DISCHARGED

  // To-Do, once BillingResidentEntries API returns isOutOfFacility, don't need to read from useCurrentFacility
  const { allPeople } = useCurrentFacility()
  const personStats = getOrElse(allPeople, []).find(
    (r) => r.person.id === personId
  )
  const { isOutOfFacility } = personStats?.person || {}
  const roomNumber =
    roomDetails &&
    formatRoomNumber(roomDetails.roomNumber, roomDetails.bedNumber)

  return (
    <TrWithHoverEffect
      onClick={() =>
        history.push(billingRouteForPerson(orgId, facilityId, personId))
      }
      noBottomLine={false}
    >
      <NameTd className={tw`text-gray-01`}>
        <div className={tw`flex items-center align-middle`}>
          <ProfileImage
            person={{ name, gender }}
            profilePhotoUrl={profilePictureUrl}
          />
          <div className={tw`ml-2`}>
            <div className={tw`flex`}>
              {getFirstAndLastName(name)}
              {isMovedOut && <BillingInfoPill>Moved Out</BillingInfoPill>}
              {isOutOfFacility && <BillingInfoPill>Away</BillingInfoPill>}
            </div>
          </div>
        </div>
      </NameTd>
      <TdWithExtraPadding>{roomNumber}</TdWithExtraPadding>
      <TdWithExtraPadding>{pendingBillingEvents}</TdWithExtraPadding>
      <TdWithExtraPadding>
        {formatCurrencyForBilling(totalBalanceCents)}
      </TdWithExtraPadding>
      <TdWithExtraPadding>
        {formatCurrencyForBilling(statementBalanceCents)}
      </TdWithExtraPadding>
      <TdWithExtraPadding className={tw`text-gray-07`}>
        {lastInvoice && (
          <div className={tw`flex`}>
            <BillingInvoicePill
              id={lastInvoice.meta.id}
              invoiceData={lastInvoice.data}
            />
            <ButtonLink
              className={tw`ml-[16px] leading-[12px] text-gray-07 underline hover:font-semibold hover:text-gray-07`}
              onClick={(ev: MouseEvent<HTMLButtonElement>) => {
                ev.preventDefault()
                ev.stopPropagation()

                const invoicePath = billingInvoicePreviewRoute(
                  orgId,
                  facilityId,
                  personId,
                  lastInvoice.meta.id
                )
                history.push(invoicePath)
              }}
            >
              view
            </ButtonLink>
          </div>
        )}
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
