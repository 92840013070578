import {
  HeightUnit,
  PainUnit,
} from '@augusthealth/models/com/august/protos/vital'
import { FieldPath, FieldValues } from 'react-hook-form'
import { match } from 'ts-pattern'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  BloodPressureUnit,
  BloodSugarUnit,
  BloodPressurePosition as BPP,
  BloodSugarTiming as BST,
  HeartRateUnit,
  OxygenSaturationUnit,
  RespiratoryRateUnit,
  SupplementalOxygen as SO,
  TemperatureUnit,
  TemperatureType as TT,
  Vital,
  VitalType,
  WeightUnit,
} from '@shared/types/vital'

export const Units = {
  lbs: WeightUnit.WEIGHT_UNIT_POUNDS,
  degF: TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT,
  mmHg: BloodPressureUnit.BLOOD_PRESSURE_UNIT_MILLIMETERS_OF_MERCURY,
  bpm: HeartRateUnit.HEART_RATE_UNIT_BEATS_PER_MINUTE,
  brpm: RespiratoryRateUnit.RESPIRATORY_RATE_UNIT_BREATHS_PER_MINUTE,
  o2: OxygenSaturationUnit.OXYGEN_SATURATION_UNIT_PERCENT,
  mgdL: BloodSugarUnit.BLOOD_SUGAR_UNIT_MILLIGRAMS_PER_DECILITER,
  inch: HeightUnit.HEIGHT_UNIT_INCHES,
  pain: PainUnit.PAIN_UNIT_ONE_TO_TEN,
}

export const UnitsLabel = {
  lbs: 'lbs',
  degF: '°F',
  mmHg: 'mmHg',
  bpm: 'bpm',
  brpm: 'bpm', // bpm (breaths per minute); users have the context to know what this means
  o2: '%',
  mgdL: 'mg/dL',
  inch: 'inch',
  pain: '/10',
}

export enum VitalsType {
  WEIGHT = 'weight',
  TEMPERATURE = 'temperature',
  HEART_RATE = 'heartRate',
  BLOOD_PRESSURE = 'bloodPressure',
  RESPIRATORY_RATE = 'respiratoryRate',
  OXYGEN_SATURATION = 'oxygenSaturation',
  BLOOD_SUGAR = 'bloodSugar',
}

export enum ParameterizedVitalsType {
  PAIN = 'pain',
  HEIGHT = 'height',
  SYSTOLIC_BLOOD_PRESSURE = 'systolic',
  DIASTOLIC_BLOOD_PRESSURE = 'diastolic',
}

export type VitalsWithDropdowns =
  | VitalsType.TEMPERATURE
  | VitalsType.BLOOD_PRESSURE
  | VitalsType.OXYGEN_SATURATION
  | VitalsType.BLOOD_SUGAR
  | ParameterizedVitalsType.SYSTOLIC_BLOOD_PRESSURE
  | ParameterizedVitalsType.DIASTOLIC_BLOOD_PRESSURE

export const VitalsTypeDropdowns: Record<VitalsWithDropdowns, string> = {
  [VitalsType.TEMPERATURE]: 'temperatureType',
  [VitalsType.BLOOD_PRESSURE]: 'bloodPressurePosition',
  [VitalsType.OXYGEN_SATURATION]: 'supplementalOxygen',
  [VitalsType.BLOOD_SUGAR]: 'bloodSugarTiming',
  [ParameterizedVitalsType.SYSTOLIC_BLOOD_PRESSURE]: 'bloodPressurePosition',
  [ParameterizedVitalsType.DIASTOLIC_BLOOD_PRESSURE]: 'bloodPressurePosition',
}

export type AllVitalTypes = VitalsType | ParameterizedVitalsType

export const VitalTypeEnumToVitalsType = (
  vitalType: VitalType
): VitalsType | null => {
  switch (vitalType) {
    case VitalType.VITAL_TYPE_WEIGHT:
      return VitalsType.WEIGHT
    case VitalType.VITAL_TYPE_TEMPERATURE:
      return VitalsType.TEMPERATURE
    case VitalType.VITAL_TYPE_HEART_RATE:
      return VitalsType.HEART_RATE
    case VitalType.VITAL_TYPE_BLOOD_PRESSURE:
      return VitalsType.BLOOD_PRESSURE
    case VitalType.VITAL_TYPE_RESPIRATORY_RATE:
      return VitalsType.RESPIRATORY_RATE
    case VitalType.VITAL_TYPE_OXYGEN_SATURATION:
      return VitalsType.OXYGEN_SATURATION
    case VitalType.VITAL_TYPE_BLOOD_SUGAR:
      return VitalsType.BLOOD_SUGAR
    default:
      return null
  }
}

export interface VitalsFormData {
  date: Date | null
  time: string | null
  weight: string
  temperature: string
  heartRate: string
  systolic: string
  diastolic: string
  respiratoryRate: string
  oxygenSaturation: string
  bloodSugar: string
  temperatureType?: OptionTypeBase<TT> | null
  bloodPressurePosition?: OptionTypeBase<BPP> | null
  supplementalOxygen?: OptionTypeBase<SO> | null
  bloodSugarTiming?: OptionTypeBase<BST> | null
  note?: string | null
}

export const BloodSugarOptions = [
  { label: 'Before Meal', value: BST.BLOOD_SUGAR_TIMING_BEFORE_MEAL },
  { label: 'After Meal', value: BST.BLOOD_SUGAR_TIMING_AFTER_MEAL },
  { label: 'Fasting', value: BST.BLOOD_SUGAR_TIMING_FASTING },
  { label: 'Bedtime', value: BST.BLOOD_SUGAR_TIMING_BEDTIME },
  { label: 'Random', value: BST.BLOOD_SUGAR_TIMING_RANDOM },
]
export const TemperatureOptions = [
  { label: 'Axillary', value: TT.TEMPERATURE_TYPE_AXILLARY },
  { label: 'Ear', value: TT.TEMPERATURE_TYPE_EAR },
  { label: 'Forehead', value: TT.TEMPERATURE_TYPE_FOREHEAD },
  { label: 'Oral', value: TT.TEMPERATURE_TYPE_ORAL },
  { label: 'Rectal', value: TT.TEMPERATURE_TYPE_RECTAL },
]
export const OxygenSaturationOptions = [
  { label: '0.5 L', value: SO.SUPPLEMENTAL_OXYGEN_0_5_L },
  { label: '1.0 L', value: SO.SUPPLEMENTAL_OXYGEN_1_0_L },
  { label: '1.5 L', value: SO.SUPPLEMENTAL_OXYGEN_1_5_L },
  { label: '2.0 L', value: SO.SUPPLEMENTAL_OXYGEN_2_0_L },
  { label: '2.5 L', value: SO.SUPPLEMENTAL_OXYGEN_2_5_L },
  { label: '3.0 L', value: SO.SUPPLEMENTAL_OXYGEN_3_0_L },
  { label: '3.5 L', value: SO.SUPPLEMENTAL_OXYGEN_3_5_L },
  { label: '4.0 L', value: SO.SUPPLEMENTAL_OXYGEN_4_0_L },
  { label: '4.5 L', value: SO.SUPPLEMENTAL_OXYGEN_4_5_L },
  { label: '5.0 L', value: SO.SUPPLEMENTAL_OXYGEN_5_0_L },
]
export const BloodPressureOptions = [
  { label: 'Lying', value: BPP.BLOOD_PRESSURE_POSITION_LYING },
  { label: 'Sitting', value: BPP.BLOOD_PRESSURE_POSITION_SITTING },
  { label: 'Standing', value: BPP.BLOOD_PRESSURE_POSITION_STANDING },
]

type VitalsInputData<Form extends FieldValues> = {
  name: FieldPath<Form>
  label: string
  unitsLabel: string
  iconClassName: string
  dropdownName?: FieldPath<Form>
  options?: OptionTypeBase<BST | SO | BPP | TT>[]
  placeholder?: string
}

export function VITALS_INPUT_PROPS_MAP<Form extends FieldValues>(
  vitalType: AllVitalTypes
): VitalsInputData<Form> {
  return match(vitalType)
    .with(VitalsType.WEIGHT, () => ({
      name: 'weight' as FieldPath<Form>,
      label: 'Weight',
      unitsLabel: UnitsLabel.lbs,
      iconClassName: 'fa-fw fa-solid fa-weight-scale',
    }))
    .with(VitalsType.TEMPERATURE, () => ({
      name: 'temperature' as FieldPath<Form>,
      label: 'Temperature',
      unitsLabel: UnitsLabel.degF,
      iconClassName: 'fa-fw fa-solid fa-temperature-three-quarters',
      dropdownName: VitalsTypeDropdowns[VitalsType.TEMPERATURE],
      options: TemperatureOptions,
      placeholder: 'Type...',
    }))
    .with(VitalsType.HEART_RATE, () => ({
      name: 'heartRate' as FieldPath<Form>,
      label: 'Pulse',
      unitsLabel: UnitsLabel.bpm,
      iconClassName: 'fa-fw fa-regular fa-wave-pulse',
    }))
    .with(VitalsType.BLOOD_PRESSURE, () => ({
      name: 'systolic' as FieldPath<Form>,
      label: 'Blood pressure',
      unitsLabel: UnitsLabel.mmHg,
      iconClassName: 'fa-fw fa-solid fa-heart-circle-bolt',
      dropdownName: VitalsTypeDropdowns[VitalsType.BLOOD_PRESSURE],
      options: BloodPressureOptions,
      placeholder: 'Position...',
    }))
    .with(VitalsType.RESPIRATORY_RATE, () => ({
      name: 'respiratoryRate' as FieldPath<Form>,
      label: 'Respiratory rate',
      unitsLabel: UnitsLabel.brpm,
      iconClassName: 'fa-fw fa-solid fa-monitor-waveform',
    }))
    .with(VitalsType.OXYGEN_SATURATION, () => ({
      name: 'oxygenSaturation' as FieldPath<Form>,
      label: 'Oxygen saturation',
      unitsLabel: UnitsLabel.o2,
      iconClassName: 'fa-fw fa-solid fa-lungs',
      dropdownName: VitalsTypeDropdowns[VitalsType.OXYGEN_SATURATION],
      options: OxygenSaturationOptions,
      placeholder: 'Supplemental O₂ ...',
    }))
    .with(VitalsType.BLOOD_SUGAR, () => ({
      name: 'bloodSugar' as FieldPath<Form>,
      label: 'Blood sugar',
      unitsLabel: UnitsLabel.mgdL,
      iconClassName: 'fa-fw fa-solid fa-droplet',
      dropdownName: VitalsTypeDropdowns[VitalsType.BLOOD_SUGAR],
      options: BloodSugarOptions,
      placeholder: 'Timing...',
    }))
    .with(ParameterizedVitalsType.SYSTOLIC_BLOOD_PRESSURE, () => ({
      name: 'systolic' as FieldPath<Form>,
      label: 'Systolic blood pressure',
      unitsLabel: UnitsLabel.mmHg,
      iconClassName: 'fa-fw fa-solid fa-heart-circle-bolt',
      dropdownName:
        VitalsTypeDropdowns[ParameterizedVitalsType.SYSTOLIC_BLOOD_PRESSURE],
      options: BloodPressureOptions,
      placeholder: 'Position...',
    }))
    .with(ParameterizedVitalsType.DIASTOLIC_BLOOD_PRESSURE, () => ({
      name: 'diastolic' as FieldPath<Form>,
      label: 'Diastolic blood pressure',
      unitsLabel: UnitsLabel.mmHg,
      iconClassName: 'fa-fw fa-solid fa-heart-circle-bolt',
      dropdownName:
        VitalsTypeDropdowns[ParameterizedVitalsType.DIASTOLIC_BLOOD_PRESSURE],
      options: BloodPressureOptions,
      placeholder: 'Position...',
    }))
    .with(ParameterizedVitalsType.PAIN, () => ({
      name: 'pain' as FieldPath<Form>,
      label: 'Pain',
      unitsLabel: UnitsLabel.pain,
      iconClassName: 'fa-fw fa-solid fa-face-head-bandage',
    }))
    .with(ParameterizedVitalsType.HEIGHT, () => ({
      name: 'height' as FieldPath<Form>,
      label: 'Height',
      unitsLabel: UnitsLabel.inch,
      iconClassName: 'fa-fw fa-solid fa-line-height',
    }))
    .exhaustive()
}

export function getVitalValueForType({
  vital,
  vitalType,
}: {
  vital: Vital
  vitalType: VitalsType
}) {
  if (vital.panel?.[vitalType]) {
    if (vitalType === VitalsType.BLOOD_PRESSURE) {
      return `${vital.panel[vitalType].systolic?.value}/${vital.panel[vitalType].diastolic?.value}`
    }

    return vital.panel[vitalType].value
  }

  return '--'
}

export type QueuedVital = Vital & {
  queue: Promise<{ id?: string | number; noteId?: string }>
  status: 'untouched' | 'saving' | 'saved'
  note?: string | null
}

export function getDefaultVitalValues(vitals?: QueuedVital): VitalsFormData {
  const today = new Date()

  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  // Lint rule disabled because `panel` is required on the type but is not actually present all the time
  return {
    date: vitals?.recordedAt ? new Date(vitals.recordedAt) : today,
    time: today.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5),
    weight: vitals?.panel?.weight?.value?.toString() ?? '',
    temperature: vitals?.panel?.temperature?.value?.toString() ?? '',
    heartRate: vitals?.panel?.heartRate?.value?.toString() ?? '',
    respiratoryRate: vitals?.panel?.respiratoryRate?.value?.toString() ?? '',
    oxygenSaturation: vitals?.panel?.oxygenSaturation?.value?.toString() ?? '',
    bloodSugar: vitals?.panel?.bloodSugar?.value?.toString() ?? '',
    systolic: vitals?.panel?.bloodPressure?.systolic?.value?.toString() ?? '',
    diastolic: vitals?.panel?.bloodPressure?.diastolic?.value?.toString() ?? '',
    temperatureType:
      TemperatureOptions.find(
        (o) => o.value === vitals?.panel?.temperature?.type
      ) ?? null,
    bloodPressurePosition:
      BloodPressureOptions.find(
        (o) => o.value === vitals?.panel?.bloodPressure?.position
      ) ?? null,
    bloodSugarTiming:
      BloodSugarOptions.find(
        (o) => o.value === vitals?.panel?.bloodSugar?.timing
      ) ?? null,
    supplementalOxygen:
      OxygenSaturationOptions.find(
        (o) => o.value === vitals?.panel?.oxygenSaturation?.supplemental
      ) ?? null,
    note: vitals?.note ?? null,
  }
  /* eslint-enable @typescript-eslint/no-unnecessary-condition */
}
