import { MedicationOrder } from '@augusthealth/models/com/august/protos/medication_order'
import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { Controller, useFormContext } from 'react-hook-form'
import { DropdownIndicatorProps } from 'react-select'
import ButtonLink from '@shared/components/ButtonLink'
import { Label } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { MedOrderFormData } from '@shared/types/medication_order'
import { SCHEDULE_TYPE_OPTIONS } from '@shared/utils/medicationStatement'
import { twx } from '@shared/utils/tailwind'
import { isTaperDosageType, PrimaryDropdownStyleConfig } from './helpers'

type Props = {
  order: MedicationOrder
  readOnly: boolean
  disableDropdown?: boolean
  onRemove?: () => void
  onChangeDoseType?: (selectedDoseType: DosageType, index: number) => void
  forceSelection?: DosageType
  options?: OptionTypeBase<DosageType>[]
  index: number
}

function WhiteDropdownIndicator({ isDisabled }: DropdownIndicatorProps) {
  return (
    <i
      className={twx('fas fa-solid fa-caret-down mr-[12px] text-[12px]', {
        'text-secondary-07': isDisabled,
        'text-white': !isDisabled,
      })}
    />
  )
}

export const ScheduleHeader = ({
  order,
  readOnly,
  onRemove,
  onChangeDoseType,
  disableDropdown = false,
  forceSelection,
  options = SCHEDULE_TYPE_OPTIONS,
  index,
}: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { control, watch } = methods

  const originalDoseLength =
    order.medicationStatement!.dosageInstruction?.length ?? 1
  const doseLength = watch('doses')?.length ?? 0

  const selectName = `doses.${index}.userSetDosageType` as const

  const doseType = watch(selectName)

  const disableRemove =
    doseLength === 1 ||
    doseLength <= originalDoseLength ||
    (doseLength <= 2 && isTaperDosageType(doseType))

  return (
    <div className={'mb-[24px] flex flex-row items-center justify-between'}>
      <Label visuallyHidden htmlFor={selectName} id={`label-${selectName}`}>
        Schedule
      </Label>
      <Controller
        control={control}
        name={selectName}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => {
          const selectedValue = forceSelection ?? value
          return (
            <StyledSelect
              aria-labelledby={`label-${selectName}`}
              name={selectName}
              inputId={selectName}
              id={selectName}
              instanceId={selectName}
              options={options}
              placeholder="Select..."
              onChange={(e: OptionTypeBase<DosageType>) => {
                onChange(e.value)
                onChangeDoseType?.(e.value, index)
              }}
              value={options.find((opt) => opt.value === selectedValue)}
              isDisabled={disableDropdown || readOnly}
              styles={PrimaryDropdownStyleConfig}
              components={{
                DropdownIndicator: WhiteDropdownIndicator,
              }}
            />
          )
        }}
      />
      {onRemove && !readOnly && (
        <ButtonLink
          icon={'fas fa-circle-minus'}
          className={'text-[14px]'}
          onClick={onRemove}
          disabled={disableRemove}
        >
          Remove
        </ButtonLink>
      )}
    </div>
  )
}
