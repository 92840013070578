import { MedicationClass } from '@augusthealth/models/com/august/protos/medication'
import { AdditionalInstructions } from '@augusthealth/models/com/august/protos/medication_statement'
import { useFormContext } from 'react-hook-form'
import { MedOrderFormData } from '@shared/types/medication_order'
import { twx } from '@shared/utils/tailwind'
import { SpecialtyBadge } from '@app/components/Residents/Medications/MedicationSpecialtyBadges/SpecialtyBadge'

export const MedicationSpecialtyBadges = ({
  className,
  readOnly = false,
}: {
  className?: string
  readOnly?: boolean
}) => {
  const { watch, setValue } = useFormContext<MedOrderFormData>()
  const medicationClasses = watch('medicationClass') || []
  const medAdditionalInstructions = watch('medAdditionalInstructions') || []
  const badgeOptions = { shouldDirty: true }
  const badges = [
    {
      text: 'Treatment',
      onClick: (e: React.FormEvent<HTMLInputElement>) => {
        setValue('isTreatment', e.currentTarget.checked ?? false, badgeOptions)
      },
      selected: watch('isTreatment'),
      hidden: false,
    },
    {
      text: 'Psychotropic',
      onClick: (e: React.FormEvent<HTMLInputElement>) => {
        const shouldBeSelected = e.currentTarget.checked ?? false
        let updatedMedClasses = [...medicationClasses]
        if (shouldBeSelected) {
          updatedMedClasses.push(MedicationClass.MEDICATION_CLASS_PSYCHOTROPIC)
        } else {
          updatedMedClasses = updatedMedClasses.filter(
            (medClass) =>
              medClass !== MedicationClass.MEDICATION_CLASS_PSYCHOTROPIC
          )
        }
        setValue('medicationClass', updatedMedClasses, badgeOptions)
      },
      selected: medicationClasses.includes(
        MedicationClass.MEDICATION_CLASS_PSYCHOTROPIC
      ),
      hidden: false,
    },
    {
      text: 'Narcotic',
      onClick: (e: React.FormEvent<HTMLInputElement>) => {
        const shouldBeSelected = e.currentTarget.checked ?? false
        let updatedMedClasses = [...medicationClasses]
        if (shouldBeSelected) {
          updatedMedClasses.push(MedicationClass.MEDICATION_CLASS_NARCOTIC)
        } else {
          updatedMedClasses = updatedMedClasses.filter(
            (medClass) => medClass !== MedicationClass.MEDICATION_CLASS_NARCOTIC
          )
        }
        setValue('medicationClass', updatedMedClasses, badgeOptions)
      },
      selected: medicationClasses.includes(
        MedicationClass.MEDICATION_CLASS_NARCOTIC
      ),
      hidden: false,
    },
    {
      text: 'Before Meal',
      onClick: (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
          setValue(
            'medAdditionalInstructions',
            [AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS],
            badgeOptions
          )
        } else {
          setValue(
            'medAdditionalInstructions',
            medAdditionalInstructions.filter(
              (ai) =>
                ai !==
                AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS
            ),
            badgeOptions
          )
        }
      },
      selected: medAdditionalInstructions.includes(
        AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS
      ),
      hidden: medAdditionalInstructions.includes(
        AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS
      ),
    },
    {
      text: 'After Meal',
      onClick: (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
          setValue(
            'medAdditionalInstructions',
            [AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS],
            badgeOptions
          )
        } else {
          setValue(
            'medAdditionalInstructions',
            medAdditionalInstructions.filter(
              (ai) =>
                ai !==
                AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS
            ),
            badgeOptions
          )
        }
      },
      selected: medAdditionalInstructions.includes(
        AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS
      ),
      hidden: medAdditionalInstructions.includes(
        AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS
      ),
    },
    {
      text: 'Self-administer',
      onClick: (e: React.FormEvent<HTMLInputElement>) => {
        setValue(
          'selfAdministered',
          e.currentTarget.checked ?? false,
          badgeOptions
        )
      },
      selected: watch('selfAdministered'),
      hidden: false,
    },
  ]

  return (
    <div className={twx(`mt-2 flex items-center`, className)}>
      {badges.map((badge) => {
        if (badge.hidden) {
          return null
        }

        return (
          <SpecialtyBadge
            key={badge.text}
            text={badge.text}
            onClick={badge.onClick}
            selected={badge.selected}
            readOnly={readOnly}
          />
        )
      })}
    </div>
  )
}
