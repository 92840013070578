import classNames from 'classnames'
import debounce from 'debounce-promise'
import { Controller } from 'react-hook-form'
import { DropdownIndicatorProps } from 'react-select'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { Label } from '@shared/components/Labels'
import {
  AsyncCreatableStyledSelect,
  CreatableStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { MedicationOrder } from '@shared/types/medication_order'
import { tw } from '@shared/utils/tailwind'
import {
  FormattedRxTerm,
  getAndCacheMedications,
  MedicationSearchStyles,
  StrengthAndForm,
} from '@app/components/Residents/Medications/MedicationSearch/helpers'
import useMedicationSearch from '@app/components/Residents/Medications/MedicationSearch/useMedicationSearch'
import { MedicationSpecialtyBadges } from '@app/components/Residents/Medications/MedicationSpecialtyBadges/MedicationSpecialtyBadges'

function XLDropdownIndicator(props: DropdownIndicatorProps) {
  const classes = classNames(
    'fas fa-solid fa-caret-down text-[16px] leading-[24px]',
    {
      'text-secondary-07': props.isDisabled,
      'text-gray-03': !props.isDisabled,
      'visually-hidden': props.options.length === 0 || props.hasValue,
      'mr-[10px]': !props.hasValue,
    }
  )

  return <i className={classes} />
}

export default function MedicationSearchAndInstructions({
  order,
  mode = 'new',
}: {
  order?: MedicationOrder
  mode?: 'edit' | 'new'
}) {
  const {
    control,
    formState,
    medicationOptions,
    reset,
    register,
    rxTermResponse,
    selectedMedication,
    setRxTermResponse,
    setStrengthAndFormOptions,
    setValue,
    strengthAndFormOptions,
  } = useMedicationSearch({ order })

  const isEditingOrder = mode === 'edit'

  return (
    <fieldset className={'w-full'}>
      <Controller
        control={control}
        name={'displayName'}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <Label htmlFor="displayName" visuallyHidden>
                Medication name
              </Label>
              <AsyncCreatableStyledSelect
                id="displayName"
                instanceId="displayName"
                openMenuOnFocus={true}
                name="displayName"
                className={'mb-[8px]'}
                loadOptions={debounce(
                  getAndCacheMedications(setRxTermResponse),
                  750
                )}
                value={
                  medicationOptions.find(
                    (re) => re.value.displayName === value
                  ) ?? null
                }
                onChange={(e: OptionTypeBase<FormattedRxTerm>) => {
                  if (e) {
                    onChange(e.value.displayName)
                    setValue('codeName', e.value.codeName)

                    const optionsForMed =
                      e.value.strengthsAndForms.length > 0
                        ? e.value.strengthsAndForms.map((saf) => ({
                            label: saf.strengthAndForm,
                            value: saf,
                          }))
                        : []
                    setStrengthAndFormOptions(optionsForMed)
                  } else {
                    if (isEditingOrder) {
                      setValue('displayName', undefined)
                      setValue('rxCui', undefined)
                      setValue('codeName', undefined)
                      setValue('strengthAndForm', undefined)
                    } else {
                      reset()
                    }
                    setStrengthAndFormOptions([])
                    setRxTermResponse([])
                  }
                }}
                placeholder={'Enter medication or treatment...'}
                isClearable
                styles={MedicationSearchStyles(!!error)}
                components={{
                  DropdownIndicator: XLDropdownIndicator,
                }}
                onCreateOption={(val: string) => {
                  if (val.trim().length > 0) {
                    onChange(val)
                    setStrengthAndFormOptions([])
                    setValue('codeName', undefined)
                  }
                }}
                noOptionsMessage={() => {
                  return rxTermResponse?.length > 0 && !value
                    ? 'No Options'
                    : null
                }}
              />
            </>
          )
        }}
      />
      {(!!selectedMedication || isEditingOrder) && (
        <div>
          <Controller
            control={control}
            name={'strengthAndForm'}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <Label htmlFor="strengthAndForm" visuallyHidden>
                    Medication strength and form factor
                  </Label>
                  <CreatableStyledSelect
                    id="strengthAndForm"
                    instanceId="strengthAndForm"
                    openMenuOnFocus={true}
                    placeholder="e.g. 325mg Tab"
                    name="strengthAndForm"
                    value={
                      strengthAndFormOptions.find(
                        (opt) => opt?.value.strengthAndForm === value
                      ) ?? null
                    }
                    options={strengthAndFormOptions}
                    isClearable
                    styles={MedicationSearchStyles(!!error)}
                    components={{
                      DropdownIndicator: XLDropdownIndicator,
                    }}
                    isDisabled={!selectedMedication}
                    onChange={(e: OptionTypeBase<StrengthAndForm> | null) => {
                      if (e) {
                        onChange(e.value.strengthAndForm)
                        setValue('rxCui', e.value.rxCui)
                      } else {
                        onChange(null)
                        setValue('rxCui', undefined)
                      }
                    }}
                    onCreateOption={(val: string) => {
                      if (val.trim().length > 0) {
                        onChange(val)
                        setStrengthAndFormOptions([
                          {
                            label: val,
                            value: {
                              strengthAndForm: val,
                              rxCui: undefined,
                            },
                          },
                        ])
                        setValue('rxCui', undefined)
                      }
                    }}
                    noOptionsMessage={() => {
                      return strengthAndFormOptions?.length > 0 && !value
                        ? 'No Options'
                        : null
                    }}
                  />
                </>
              )
            }}
          />
          <BasicTextarea
            {...register('sig', {
              required: true,
            })}
            placeholder={'Instructions...'}
            className={tw`mt-[8px] w-full max-w-full`}
            showErrorBorder={!!formState.errors.sig}
          />
          <MedicationSpecialtyBadges />
        </div>
      )}
    </fieldset>
  )
}
