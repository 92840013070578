import { ModalProps as BaseModalProps } from '@mui/base'
import { Modal as BaseMuiModal } from '@mui/base/Modal'
import React, { KeyboardEvent, MouseEvent } from 'react'
import { Button, ButtonProps } from '@shared/components/baseMui/Button'
import { tw, twx } from '@shared/utils/tailwind'

export type ModalProps = Omit<BaseModalProps, 'children'> & {
  id: string
  children: React.ReactNode
  onClose: () => void
  contentClassName?: string
  backgroundClassName?: string
  disableBackgroundClick?: boolean
}

export const ModalBackdropClasses = twx(
  'fixed right-0 top-0 z-[1000]',
  'flex bg-[rgb(0,0,0)]/[.8] h-dvh w-screen print:static print:overflow-visible'
)

/**
 * Used as the basis for Modal, FullScreenModal, Drawer, and RequiredActionModal components
 * Not to be used directly
 */
export const BaseModal = ({
  id,
  open,
  backgroundClassName,
  closeModal,
  requestedEnter,
  onExited,
  children,
  ...rest
}: {
  id: string
  open: boolean
  backgroundClassName: string
  closeModal?: () => void
  requestedEnter: boolean
  onExited: () => void
  children: React.ReactNode
}) => {
  const closeOnBackgroundClick = (event: MouseEvent<HTMLDivElement>): void => {
    const targetId = (event.target as HTMLDivElement).id

    if (targetId === id) {
      closeModal?.()
      onExited()
    }
  }

  const escapeClosesModal = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault()
      closeModal?.()
      onExited()
    }
  }

  return (
    <BaseMuiModal
      open={open}
      slotProps={{
        root: {
          className: twx(ModalBackdropClasses, backgroundClassName),
          onClick: closeModal && closeOnBackgroundClick,
          onKeyDown: closeModal && escapeClosesModal,
        },
      }}
      closeAfterTransition={true}
      {...rest}
    >
      <div
        id={id}
        className={twx(
          'opacity-1 motion-reduce:animation-none flex h-full w-full overflow-hidden',
          backgroundClassName,
          {
            'animate-fade-in': requestedEnter,
            'animate-fade-out': !requestedEnter,
          }
        )}
        onTransitionEnd={() => {
          if (!requestedEnter) {
            onExited()
          }
        }}
      >
        {children}
      </div>
    </BaseMuiModal>
  )
}
/**
 * Stylized Modal title
 * @param children - contents of Modal title
 */
export const ModalTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      role="heading"
      className={tw`mb-8 border-b border-b-secondary-10 pb-4 text-[24px] font-medium leading-[36px] text-secondary-02`}
    >
      {children}
    </div>
  )
}
type ModalFooterButtonProps = ButtonProps & {
  label?: string
}
/**
 * Styled Modal Buttons - Cancel and Confirm
 * Buttons props match the underlying button component
 * @param containerClassName - additional classes for the container wrapping the buttons
 * @param cancelProps - props for the Cancel Button (see ModalFooterButtonProps)
 * @param confirmProps - props for the Confirm Button (see ModalFooterButtonProps)
 */
export const ModalButtons = ({
  containerClassName,
  cancelProps = {},
  confirmProps = {},
}: {
  containerClassName?: string
  cancelProps?: ModalFooterButtonProps
  confirmProps?: ModalFooterButtonProps
}) => {
  const {
    label: cancelLabel,
    className: cancelClassName,
    ...cancelButtonRest
  } = cancelProps
  const {
    label: confirmLabel,
    className: confirmClassName,
    ...confirmButtonRest
  } = confirmProps

  return (
    <div
      className={twx(
        'mt-8 flex flex-row items-center justify-start gap-2',
        containerClassName
      )}
    >
      <Button
        buttonStyle={'secondary-outline'}
        className={twx('w-[138px]', cancelClassName)}
        type={'button'}
        {...cancelButtonRest}
      >
        {cancelLabel || 'Cancel'}
      </Button>
      <Button
        buttonStyle={'primary-fill'}
        className={twx('w-[138px]', confirmClassName)}
        type={'submit'}
        {...confirmButtonRest}
      >
        {confirmLabel || 'Confirm'}
      </Button>
    </div>
  )
}
/**
 * Close button used in modals
 * Used by Modal, FullScreenModal, and Drawer components
 * @param onClick - function to trigger when button is clicked (typically the `onClose` function of the consuming modal)
 * @param className - additional classes for the component
 * @constructor
 */
export const CloseButton = ({
  onClick,
  className,
}: {
  onClick: ModalProps['onClose']
  className?: string
}) => {
  return (
    <button
      title={'Close Dialog'}
      tabIndex={0}
      name={'xmark'}
      className={twx(
        'z-[1001] cursor-pointer text-[24px] text-white transition-all hover:text-scrim-white',
        className
      )}
      onClick={onClick}
    >
      <i className={twx('fa-solid fa-fw fa-xmark font-normal')} />
    </button>
  )
}

/**
 * Lays on top of the current modal content; will match the size of the original modal content
 * Use for confirmations or additional actions taken within the context of a modal
 */
export const NestedModalConfirmation = ({
  children,
  className,
  ...rest
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      data-testid={'nested-modal'}
      className={twx(
        'absolute bottom-0 left-0 right-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center bg-background',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
