import {
  CustomDisplayField,
  FormDisplay,
} from '@augusthealth/models/com/august/protos/signable_form'
import { get } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { GenericTooltip } from '@shared/components/Tooltips/GenericTooltip'
import { tw } from '@shared/utils/tailwind'
import {
  getCustomFieldName,
  getFormConfigurationFieldName,
  getFormConfigurationOptionsName,
  getFormConfigurationQuestionsName,
  getPageName,
} from '../helpers'
import {
  areSameTypes,
  buildContactDropdownName,
  CONFIGURATION_OPTIONS,
  ConfigurationOption,
  ConfigurationType,
  getContactDropdownVariableName,
  getNameRegisterOptions,
  isValidVariableName,
  TYPES_WITH_OPTIONS_FIELD,
  TYPES_WITH_QUESTIONS,
  TYPES_WITH_VARIABLE_NAME_FIELD,
  TYPES_WITHOUT_REQUIRED_FIELD,
} from './helpers'

export default function CustomField({
  index: fieldIndex,
  pageIndex,
  useFormReturn,
}: {
  index: number
  pageIndex: number
  useFormReturn: UseFormReturn<FormDisplay>
}) {
  const {
    clearErrors,
    control,
    register,
    getValues,
    setValue,
    formState,
    trigger,
    watch,
  } = useFormReturn
  const { errors } = formState
  const fieldName = getCustomFieldName(pageIndex, fieldIndex, 'name')
  const customFieldsName = getPageName(pageIndex, 'customFields')
  const customFields = watch(customFieldsName)
  const titleName = getFormConfigurationFieldName(
    pageIndex,
    fieldIndex,
    'title'
  )
  const descriptionName = getFormConfigurationFieldName(
    pageIndex,
    fieldIndex,
    'description'
  )
  const typeName = getFormConfigurationFieldName(pageIndex, fieldIndex, 'type')
  const optionsName = getFormConfigurationFieldName(
    pageIndex,
    fieldIndex,
    'options'
  )
  const questionsName = getFormConfigurationFieldName(
    pageIndex,
    fieldIndex,
    'questions'
  )
  const requiredName = getFormConfigurationFieldName(
    pageIndex,
    fieldIndex,
    'required'
  )
  const variableName = `variableName-${pageIndex}-${fieldIndex}`
  const type = watch(typeName) as ConfigurationType
  const requiredOptions = TYPES_WITH_OPTIONS_FIELD.includes(type)
  const requiredQuestions = TYPES_WITH_QUESTIONS.includes(type)
  const disabledRequired = TYPES_WITHOUT_REQUIRED_FIELD.includes(type)

  // Variable name is used as a helper to generate name for ContactDropdown and ContactDropdownList
  // Which is NOT registered in react-hook-form and will not be sent to API
  const includeVariableName = TYPES_WITH_VARIABLE_NAME_FIELD.includes(type)
  const options = watch(optionsName)
  const questions = watch(questionsName)
  const nameFieldError = get(errors, fieldName) as FieldError | undefined
  const hasNameFieldErrorMessage = Boolean(nameFieldError?.message)
  const defaultVariableName = getContactDropdownVariableName({
    name: getValues(fieldName) as string,
    type,
  })
  const [hasVariableNameFormatError, setHasVariableNameFormatError] =
    useState<boolean>(!isValidVariableName(defaultVariableName))

  useEffect(() => {
    if (
      type === 'CustomContactDropdown' ||
      type === 'CustomContactDropdownList'
    ) {
      void trigger(fieldName) // Detect existing error for Name
    } else {
      void clearErrors(fieldName) // Don't need to do it for other types
    }
  }, [defaultVariableName, type])

  return (
    <Card className={tw`relative mb-[8px]`}>
      <>
        <table className={tw`w-full`}>
          <tbody>
            {includeVariableName && (
              <tr>
                <td>
                  <LabelAboveInput
                    className={tw`mb-0`}
                    htmlFor={variableName}
                    uppercase={false}
                  >
                    Variable name
                  </LabelAboveInput>
                </td>
                <td>
                  <BasicInput
                    name={variableName}
                    placeholder="Enter variable name to generate Name..."
                    onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                      const inputValue = ev.target.value
                      setValue(
                        fieldName,
                        buildContactDropdownName({
                          type,
                          variableName: inputValue,
                        }),
                        { shouldDirty: true }
                      )

                      setHasVariableNameFormatError(
                        !isValidVariableName(inputValue)
                      )

                      void trigger(fieldName)
                    }}
                    defaultValue={defaultVariableName}
                    showErrorBorder={hasVariableNameFormatError}
                  />
                  {hasVariableNameFormatError && (
                    <GenericTooltip
                      className={tw`mb-[8px] mt-[4px]`}
                      caretPosition="top"
                    >
                      Only alphanumeric characters, "-" and "_" are allowed
                    </GenericTooltip>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td className={tw`w-[160px]`}>
                <LabelAboveInput
                  className={tw`mb-0`}
                  htmlFor={fieldName}
                  uppercase={false}
                  subLabel={requiredWhenError(
                    Boolean(nameFieldError && !hasNameFieldErrorMessage)
                  )}
                >
                  Name
                </LabelAboveInput>
              </td>
              <td>
                <BasicInput
                  {...register(fieldName, {
                    required: true,
                    ...getNameRegisterOptions(type),
                  })}
                  placeholder="Unique ID name"
                  readOnly={includeVariableName}
                  showErrorBorder={hasNameFieldErrorMessage}
                  className={tw`read-only:cursor-not-allowed read-only:opacity-50`}
                  title="Please edit Varable Name to generate Name or use Edit JSON popup"
                />
                {hasNameFieldErrorMessage && (
                  <GenericTooltip
                    className={tw`mb-[8px] mt-[4px]`}
                    caretPosition="top"
                  >
                    {nameFieldError?.message}
                  </GenericTooltip>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <LabelAboveInput
                  className={tw`mb-0`}
                  htmlFor={titleName}
                  uppercase={false}
                >
                  Title
                </LabelAboveInput>
              </td>
              <td>
                <BasicInput {...register(titleName)} />
              </td>
            </tr>
            <tr>
              <td>
                <LabelAboveInput
                  className={tw`mb-0`}
                  htmlFor={descriptionName}
                  uppercase={false}
                >
                  Description
                </LabelAboveInput>
              </td>
              <td>
                <BasicInput {...register(descriptionName)} />
              </td>
            </tr>
            <tr>
              <td>
                <LabelAboveInput
                  className={tw`mb-0`}
                  htmlFor={typeName}
                  uppercase={false}
                >
                  Type
                </LabelAboveInput>
              </td>
              <td>
                <Controller
                  control={control}
                  name={typeName}
                  render={({ field: { onChange, value } }) => {
                    const defaultValue =
                      CONFIGURATION_OPTIONS.find((opt: ConfigurationOption) =>
                        areSameTypes(opt.value, value)
                      ) || CONFIGURATION_OPTIONS[0]

                    return (
                      <StyledSelect
                        placeholder="Select an UI..."
                        options={CONFIGURATION_OPTIONS}
                        onChange={(opt: ConfigurationOption) => {
                          if (opt.value) {
                            onChange(opt.value)
                            if (TYPES_WITH_OPTIONS_FIELD.includes(opt.value)) {
                              if (!options) {
                                setValue(optionsName, [{}])
                              }
                            } else {
                              if (options) {
                                setValue(optionsName, undefined)
                              }
                            }
                          }
                          if (TYPES_WITH_QUESTIONS.includes(opt.value)) {
                            if (!questions) {
                              setValue(questionsName, [{}])
                            }
                          } else {
                            if (questions) {
                              setValue(questionsName, undefined)
                            }
                          }
                        }}
                        value={defaultValue}
                      />
                    )
                  }}
                />
              </td>
            </tr>
            {requiredOptions && (
              <tr>
                <td className={tw`pt-[0.5em] align-top`}>
                  <LabelAboveInput
                    className={tw`mb-0`}
                    htmlFor={optionsName}
                    uppercase={false}
                  >
                    Options
                  </LabelAboveInput>
                </td>
                <td>
                  {options && (
                    <table className={tw`w-full`}>
                      {options.map((_o: any, i: number) => {
                        const labelName = getFormConfigurationOptionsName(
                          pageIndex,
                          fieldIndex,
                          i,
                          'label'
                        )
                        const valueName = getFormConfigurationOptionsName(
                          pageIndex,
                          fieldIndex,
                          i,
                          'value'
                        )
                        const isFirstItem = i === 0
                        return (
                          <tr key={`cf-${i}`}>
                            <td>
                              <BasicInput
                                {...register(labelName)}
                                placeholder="Label"
                              />
                            </td>
                            <td>
                              <BasicInput
                                {...register(valueName)}
                                placeholder="Value"
                              />
                            </td>
                            <td
                              className={tw`p-[8px]`}
                              onClick={() =>
                                !isFirstItem &&
                                setValue(
                                  optionsName,
                                  options.filter(
                                    (_o: any, index: number) => i !== index
                                  )
                                )
                              }
                            >
                              {!isFirstItem && (
                                <i
                                  className={tw`mute-text fa-solid fa-times cursor-pointer`}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </table>
                  )}
                  <Button
                    buttonStyle="primary-fill"
                    buttonSize="xsmall"
                    onClick={() => {
                      const newOptions = options ? [...options, {}] : [{}]
                      setValue(optionsName, newOptions)
                    }}
                    type="button"
                  >
                    Add new option
                  </Button>
                </td>
              </tr>
            )}
            {requiredQuestions && (
              <tr>
                <td className={tw`pt-[0.5em] align-top`}>
                  <LabelAboveInput
                    className={tw`mb-0`}
                    htmlFor={questionsName}
                    uppercase={false}
                  >
                    Questions
                  </LabelAboveInput>
                </td>
                <td>
                  {questions && (
                    <table className={tw`w-full`}>
                      {questions.map((_o: any, i: number) => {
                        const labelName = getFormConfigurationQuestionsName(
                          pageIndex,
                          fieldIndex,
                          i,
                          'label'
                        )
                        const valueName = getFormConfigurationQuestionsName(
                          pageIndex,
                          fieldIndex,
                          i,
                          'value'
                        )
                        const isFirstItem = i === 0
                        return (
                          <tr key={`cf-${i}`}>
                            <td>
                              <BasicInput
                                {...register(labelName)}
                                placeholder="Label"
                              />
                            </td>
                            <td>
                              <BasicInput
                                {...register(valueName)}
                                placeholder="Value"
                              />
                            </td>
                            <td
                              className={tw`p-[8px]`}
                              onClick={() =>
                                !isFirstItem &&
                                setValue(
                                  questionsName,
                                  questions.filter(
                                    (_o: any, index: number) => i !== index
                                  )
                                )
                              }
                            >
                              {!isFirstItem && (
                                <i
                                  className={tw`mute-text fa-solid fa-times cursor-pointer`}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </table>
                  )}
                  <Button
                    buttonStyle="primary-fill"
                    buttonSize="xsmall"
                    onClick={() => {
                      const newQuestions = questions ? [...questions, {}] : [{}]
                      setValue(questionsName, newQuestions)
                    }}
                    type="button"
                  >
                    Add new question
                  </Button>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <LabelAboveInput
                  className={tw`mb-0 mt-[8px]`}
                  htmlFor={requiredName}
                  uppercase={false}
                >
                  Required
                </LabelAboveInput>
              </td>
              <td className={tw`pt-[8px]`}>
                <BasicCheckbox
                  {...register(requiredName)}
                  disabled={disabledRequired}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <i
          className={tw`fa-solid fa-times mute-text absolute right-[8px] top-[8px] cursor-pointer`}
          onClick={() => {
            if (customFields) {
              setValue(
                customFieldsName,
                (customFields as CustomDisplayField[]).filter(
                  (_f, i) => i !== fieldIndex
                ),
                { shouldDirty: true }
              )
            }
          }}
        />
      </>
    </Card>
  )
}
