import { endOfDay, endOfToday, isAfter, sub } from 'date-fns'
import { Schedule } from '@shared/components/MedOrderPill'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { MedicationClass } from '@shared/types/medication'
import {
  MedicationApprovalStatus,
  MedicationOrder,
  PharmacyMessageType,
} from '@shared/types/medication_order'
import {
  AdditionalInstructions,
  DosageType,
  MedicationStatement,
  MedicationStatus,
} from '@shared/types/medication_statement'
import { PharmacyPartner } from '@shared/types/pharmacy_partner'
import { UnitOfTime } from '@shared/types/timing'
import { PickPartial } from '@shared/types/utilities'
import {
  dateMessageComparator,
  fromDateToDateMessage,
} from '@shared/utils/date'

export const isScheduled = (med: MedicationStatement) => !statementHasPrn(med)

export const isOnlyPRN = (med: MedicationStatement) => {
  return !!med.dosageInstruction?.every((dose) => dose.asNeededBoolean)
}

/**
 * Returns true if any dosage instruction is PRN
 */
export const statementHasPrn = (med: MedicationStatement) =>
  !!med.dosageInstruction?.some((dose) => dose.asNeededBoolean)

export const isTreatment = (med: MedicationStatement): boolean => {
  return !!med.isTreatment
}

export const isSelfAdministered = (med: MedicationStatement): boolean => {
  return !!med.selfAdministered
}

export const isPsychotropic = (med: MedicationStatement): boolean => {
  return !!med.medication?.medicationClass?.includes(
    MedicationClass.MEDICATION_CLASS_PSYCHOTROPIC
  )
}

export const isNarcotic = (med: MedicationStatement): boolean => {
  return !!med.medication?.medicationClass?.includes(
    MedicationClass.MEDICATION_CLASS_NARCOTIC
  )
}
export const isBeforeMeal = (med: MedicationStatement): boolean => {
  return !!med.additionalInstructions?.includes(
    AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS
  )
}
export const isAfterMeal = (med: MedicationStatement): boolean => {
  return !!med.additionalInstructions?.includes(
    AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS
  )
}

export const getDeaSchedule = (
  med: MedicationStatement
): Schedule | undefined => {
  if (med.medication?.schedule && med.medication.schedule.text) {
    return `schedule ${med.medication.schedule.text}` as Schedule
  }

  return undefined
}
export const getDosageUnitDisplay = (unit: string): string => {
  switch (unit.toLowerCase()) {
    case 'ml':
      return 'mL'
    case 'mmhg':
      return 'mmHg'
    case 'mg':
      return 'mg'
    case 'g':
      return 'g'
    case 'cc':
      return 'cc'
    case '':
      return 'Unit'
    default:
      return `${unit[0].toUpperCase()}${unit.slice(1).toLowerCase()}`
  }
}

export function isDiscontinued(med: MedicationStatement) {
  if (med.statusEnum === MedicationStatus.MEDICATION_STATUS_STOPPED) {
    return true
  }

  if (med.statusEnum === MedicationStatus.MEDICATION_STATUS_ENTERED_IN_ERROR) {
    return false
  }

  const endDate = med.effectivePeriod?.endDate

  if (endDate) {
    const today = endOfToday()

    const expirationDate = endOfDay(
      new Date(endDate.year || 0, (endDate.month || 0) - 1, endDate.day)
    )

    return isAfter(today, expirationDate)
  }

  return false
}

// Added explicit isActive function because of the strange logic around MEDICATION_STATUS_ENTERED_IN_ERROR
export function isActive(med: MedicationStatement) {
  if (med.statusEnum === MedicationStatus.MEDICATION_STATUS_ENTERED_IN_ERROR) {
    return false
  }
  return !isDiscontinued(med)
}

export function isRejected(medOrder: MedicationOrder): boolean {
  return (
    medOrder.approval?.status ===
    MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_REJECTED
  )
}

export function isApproved(medOrder: MedicationOrder): boolean {
  return (
    medOrder.approval?.status ===
    MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_APPROVED
  )
}

export function canHaveMessageDiffs(medOrder: MedicationOrder): boolean {
  return (
    isPending(medOrder) &&
    !isPendingDiscontinue(medOrder) &&
    !isPendingNew(medOrder)
  )
}

export function isPending(medOrder: MedicationOrder): boolean {
  return (
    medOrder.approval?.status ===
    MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_PENDING
  )
}

export function isWeakMatch(medOrder: MedicationOrder): boolean {
  return (
    isPending(medOrder) &&
    (medOrder.sourceData?.possibleMatches?.length ?? 0) > 0
  )
}

export function isPendingNew(medOrder: MedicationOrder): boolean {
  return (
    isPending(medOrder) &&
    !isPendingUpdate(medOrder) &&
    !isPendingDiscontinue(medOrder) &&
    !isWeakMatch(medOrder)
  )
}

export function isPendingDiscontinue(medOrder: MedicationOrder): boolean {
  return (
    isPending(medOrder) &&
    !isWeakMatch(medOrder) &&
    isDiscontinuedByPharmacy(medOrder)
  )
}

export function isPendingUpdate(medOrder: MedicationOrder): boolean {
  return (
    isPending(medOrder) &&
    !isWeakMatch(medOrder) &&
    !isDiscontinuedByPharmacy(medOrder) &&
    // TODO - we'll want to embrace disctinct PharmacyMessage and
    // MedicationOrder types and move these helpers to account for those.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    medOrder.orderGroupId !== undefined
  )
}

function isDiscontinuedByPharmacy(medOrder: MedicationOrder): boolean {
  const messageType =
    medOrder.sourceData?.messageType ||
    PharmacyMessageType.PHARMACY_MESSAGE_TYPE_UNSPECIFIED
  return (
    messageType === PharmacyMessageType.PHARMACY_MESSAGE_TYPE_DISCONTINUE ||
    messageType === PharmacyMessageType.PHARMACY_MESSAGE_TYPE_CANCEL
  )
}

export function isExternal(medOrder: MedicationOrder): boolean {
  return medOrder.sourceData?.integrationSource !== undefined
}

export function isCommunityManaged(medOrder: MedicationOrder): boolean {
  return !isExternal(medOrder)
}

export const sortMedsForDestructionLog = (
  meds: MedicationOrder[]
): MedicationOrder[] => {
  const [activeMeds, discontinuedMeds] = [
    meds.filter((med) => isActive(med.medicationStatement)),
    meds.filter((med) => isDiscontinued(med.medicationStatement)),
  ]
  // meds discontinued before April 28, 2021 were not enforcing an endDate
  // in case that endDate is missing, just use 4/28/2021
  const fallbackEndDate = fromDateToDateMessage(new Date('April 28, 2021'))

  discontinuedMeds.sort((a, b) =>
    dateMessageComparator(
      b.medicationStatement.effectivePeriod?.endDate
        ? b.medicationStatement.effectivePeriod.endDate
        : fallbackEndDate,
      a.medicationStatement.effectivePeriod?.endDate
        ? a.medicationStatement.effectivePeriod.endDate
        : fallbackEndDate
    )
  )

  return [...discontinuedMeds, ...activeMeds]
}

export const isNewMedication = (
  medOrder: PickPartial<MedicationOrder, 'approval'>
): boolean => {
  return isAfter(
    new Date(medOrder.approval?.approvedBy?.modificationTime as string),
    sub(new Date(), { days: 7 })
  )
}

export const getMedicationName = (order: MedicationOrder): string => {
  const alternateName = order.medicationStatement.medication?.alternateName
  const drugName = order.medicationStatement.medication?.drugName as string

  if (alternateName && alternateName !== drugName) {
    return `${drugName} (${alternateName})`
  }

  return drugName
}

// StrengthAndForm is included in Medication Name of External (Pharmacy) order, don't need to repeat.
export const getMedicationTitleAndSubTitle = (order: MedicationOrder) => {
  const strengthAndForm = order.medicationStatement.medication?.strengthAndForm

  const isSuiteRx =
    order.sourceData?.integrationSource ===
    PharmacyPartner.PHARMACY_PARTNER_SUITE_RX

  const containsStrengthAndFormInTitle = isExternal(order) && !isSuiteRx

  return {
    title: getMedicationName(order),
    subTitle:
      !containsStrengthAndFormInTitle && strengthAndForm ? strengthAndForm : '',
  }
}

export const getMedicationRoute = (
  order: MedicationOrder
): string | undefined => {
  return order.medicationStatement.dosageInstruction?.[0]?.route
}

export const QUANTITY_UNIT_OPTIONS = [
  {
    label: 'Tabs',
    value: 'TAB',
  },
  {
    label: 'Capsules',
    value: 'CAP',
  },
  {
    label: 'Drops',
    value: 'DROP',
  },
  {
    label: 'Implants',
    value: 'IMPLANT',
  },
  {
    label: 'Inhalers',
    value: 'INHALER',
  },
  {
    label: 'Injections',
    value: 'INJECTION',
  },
  {
    label: 'milligram (mg)',
    value: 'MG',
  },
  {
    label: 'milliliters (ml)',
    value: 'ML',
  },
  {
    label: 'Patches',
    value: 'PATCH',
  },
  {
    label: 'Suppositories',
    value: 'SUPPOSITORIES',
  },
  {
    label: 'Topicals',
    value: 'TOPICAL',
  },
  {
    label: 'Units',
    value: 'UNIT',
  },
  {
    label: 'Grams',
    value: 'GRAM',
  },
  {
    label: 'Puffs',
    value: 'PUFF',
  },
  {
    label: 'Lozenges',
    value: 'LOZENGE',
  },
  {
    label: 'Bottles',
    value: 'BOTTLE',
  },
  {
    label: 'Vials',
    value: 'VIAL',
  },
  {
    label: 'Tablespoons',
    value: 'TABLESPOON',
  },
  {
    label: 'Teaspoons',
    value: 'TEASPOON',
  },
  {
    label: 'Ampules',
    value: 'AMPULE',
  },
  {
    label: 'Pads',
    value: 'PAD',
  },
]

export const getQuantityUnitOptions = (
  selectedQuantityUnit?: string
): OptionTypeBase[] => {
  const quantityUnitOptions: OptionTypeBase[] = [...QUANTITY_UNIT_OPTIONS]

  if (
    selectedQuantityUnit &&
    !quantityUnitOptions.find((opt) => opt.value === selectedQuantityUnit)
  ) {
    quantityUnitOptions.push({
      label: selectedQuantityUnit,
      value: selectedQuantityUnit,
    })
  }

  return quantityUnitOptions
}

export enum CustomPeriodUnits {
  EVERY = 'EVERY',
  SPECIFIC_DAYS = 'SPECIFIC_DAYS',
}

export const TAPER_PERIOD_UNIT_OPTIONS = [
  {
    label: 'Daily',
    value: UnitOfTime.UNIT_OF_TIME_DAY,
  },
  {
    label: 'Days of week...',
    value: UnitOfTime.UNIT_OF_TIME_WEEK,
  },
  {
    label: 'Days of month...',
    value: UnitOfTime.UNIT_OF_TIME_MONTH,
  },
  {
    label: 'Every...',
    value: CustomPeriodUnits.EVERY,
  },
]
export const ALL_PERIOD_UNIT_OPTIONS = [
  ...TAPER_PERIOD_UNIT_OPTIONS,
  {
    label: 'On this day...',
    value: CustomPeriodUnits.SPECIFIC_DAYS,
  },
]
export const EVERY_BLANK_PERIOD_UNIT_OPTIONS = [
  {
    label: 'Hours',
    value: UnitOfTime.UNIT_OF_TIME_HOUR,
  },
  {
    label: 'Days',
    value: UnitOfTime.UNIT_OF_TIME_DAY,
  },
]

const ignoredDosageTypes = [
  DosageType.DOSAGE_TYPE_UNSPECIFIED,
  DosageType.UNRECOGNIZED,
]

export const splitSupportedDosageTypes = [
  DosageType.DOSAGE_TYPE_ROUTINE,
  DosageType.DOSAGE_TYPE_PRN,
  DosageType.DOSAGE_TYPE_SLIDING_SCALE,
]
const diySupportedDosageTypes = [
  DosageType.DOSAGE_TYPE_ROUTINE,
  DosageType.DOSAGE_TYPE_PRN,
  DosageType.DOSAGE_TYPE_SLIDING_SCALE,
  DosageType.DOSAGE_TYPE_TAPER,
]
export const dosageTypeToDisplay = (dosageType: DosageType) => {
  switch (dosageType) {
    case DosageType.DOSAGE_TYPE_SPLIT:
      return 'Split'
    case DosageType.DOSAGE_TYPE_SLIDING_SCALE:
      return 'Sliding Scale'
    case DosageType.DOSAGE_TYPE_TAPER:
      return 'Taper'
    case DosageType.DOSAGE_TYPE_ROUTINE:
      return 'Routine'
    case DosageType.DOSAGE_TYPE_PRN:
      return 'PRN'
    default:
      return 'Unknown'
  }
}

const createScheduleTypeOption = (doseType: DosageType) => ({
  label: `${dosageTypeToDisplay(doseType)} Schedule`,
  value: doseType,
})
export const SCHEDULE_TYPE_OPTIONS: OptionTypeBase<DosageType>[] =
  Object.values(DosageType)
    .filter((d) => !ignoredDosageTypes.includes(d))
    .map(createScheduleTypeOption)

export const SPLIT_SCHEDULE_TYPE_OPTIONS: OptionTypeBase<DosageType>[] =
  splitSupportedDosageTypes.map(createScheduleTypeOption)

export const DIY_SCHEDULE_TYPE_OPTIONS: OptionTypeBase<DosageType>[] =
  diySupportedDosageTypes.map(createScheduleTypeOption)

export const TAPER_SCHEDULE_TYPE_OPTIONS: OptionTypeBase<DosageType>[] = [
  DosageType.DOSAGE_TYPE_TAPER,
].map(createScheduleTypeOption)

export type UnknownDosageType =
  | DosageType.DOSAGE_TYPE_UNSPECIFIED
  | DosageType.UNRECOGNIZED
  | undefined

export function isUnknownDosageType(
  dosageType: DosageType | undefined
): dosageType is UnknownDosageType {
  return [
    DosageType.DOSAGE_TYPE_UNSPECIFIED,
    DosageType.UNRECOGNIZED,
    undefined,
  ].includes(dosageType)
}
